import { useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';
import { AUTH_TOKEN_QUERY_PARAM, AUTH_TOKEN_USER_NAME } from 'src/utils/authTokenUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  redirectForAuthenticated: boolean;
};

export default function GuestGuard({ children, redirectForAuthenticated }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container redirectForAuthenticated={redirectForAuthenticated}>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children, redirectForAuthenticated }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.home;

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (redirectForAuthenticated && authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, redirectForAuthenticated, router, returnTo]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
