import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';
import { HEADER } from 'src/layouts/config-layout';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

type LogoType = 'codelab' | 'lyza_education_expanded' | 'original'

export interface LogoProps extends BoxProps {
  disableLink?: boolean;
  logoType: LogoType
}

const src = {
  'codelab': {
    'light': "/assets/icons/logo/codelab_logo_light_mode.png",
    'dark': "/assets/icons/logo/codelab_logo_dark_mode.png"
  },
  'lyza_education_expanded': {
    'light': "/assets/icons/logo/lyza_education_without_Kebbi_light_expanded.png",
    'dark': "/assets/icons/logo/lyza_education_without_Kebbi_dark_expanded.png"
  },
  'original': {
    'light': "/assets/icons/logo/lyza_education_without_Kebbi_light_mini.png",
    'dark': "/assets/icons/logo/lyza_education_without_Kebbi_dark_mini.png"
  },
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disableLink = false, logoType, sx, ...other }, ref) => {
    const settings = useSettingsContext()
    const theme = useTheme();
    const { mode } = theme.palette

    let modeAfterApparentSwitch = mode
    if (settings.navColor === 'apparent') {
      modeAfterApparentSwitch = 'dark'
    }

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={src[logoType][modeAfterApparentSwitch]}
        sx={{
          flexShrink: 1,
          height: {
            xs: 20,
            md: 24,
          },
          objectFit: 'contain',
          ...sx,
          ...(!disableLink && { cursor: 'pointer' }),
        }}
      />
    );

    if (disableLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
