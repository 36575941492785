import { DeleteMachineLearningProjectMutationVariables, GetMachineLearningProjectV2QueryVariables, MachineLearningModelType, OrgRole } from "src/API";
import { TabValue } from "src/sections/management/class/class-view";

// ----------------------------------------------------------------------
const ROBOT_MANAGEMENT_ROOT = '/robot_management'

const ROOTS = {
  AUTH: '/auth',
  ROBOT_MANAGEMENT: {
    ROOT: ROBOT_MANAGEMENT_ROOT,
    DASHBOARD: `${ROBOT_MANAGEMENT_ROOT}/dashboard`,
    FAQ_MANAGEMENT: `${ROBOT_MANAGEMENT_ROOT}/faq_management`,
    CONVERSATION_HISTORY: `${ROBOT_MANAGEMENT_ROOT}/conversation_history`
  },
  MANAGEMENT: '/management',
  CODELAB: '/codelab',
  MACHINE_LEARNING_PROJECT_ROOT: '/codelab/machine-learning/project',
  CODESPACE_PROJECT_ROOT: '/codelab/codespace/project'
};

// ----------------------------------------------------------------------

export const paths = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  pageUnauthorized: '/unauthorized', // TODO
  home: '/',
  // AUTH
  auth: {
    root: ROOTS.AUTH,
    amplify: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    },
  },
  robotManagement: {
    root: ROOTS.ROBOT_MANAGEMENT.ROOT,
    dashboard: ROOTS.ROBOT_MANAGEMENT.DASHBOARD,
    faqManagement: ROOTS.ROBOT_MANAGEMENT.FAQ_MANAGEMENT
  },
  // CODELAB
  codelab: {
    // root: ROOTS.CODELAB, // DON't use root for all projects
    allProjects: `${ROOTS.CODELAB}/all-projects`,
    project: (id: string) => `${ROOTS.CODELAB}/project/${id}`,
    machineLearning: {
      // root: `${ROOTS.CODELAB}/machine-learning`, // DON't use root for all-projects
      allProjects: `${ROOTS.CODELAB}/machine-learning/all-projects`,
      project: (id: string) => `${ROOTS.MACHINE_LEARNING_PROJECT_ROOT}/${id}`,
      projectPath: ROOTS.MACHINE_LEARNING_PROJECT_ROOT,
    },
    codespace: {
      allCodespaceProjects: `${ROOTS.CODELAB}/codespace/all-projects`,
      project: (id: string) => `${ROOTS.CODESPACE_PROJECT_ROOT}?id=${id}`,
      projectPath: ROOTS.CODESPACE_PROJECT_ROOT,
    },
  },
  // MANAGEMENT
  management: {
    root: `${ROOTS.MANAGEMENT}`,
    user: {
      root: `${ROOTS.MANAGEMENT}/user`,
      list: `${ROOTS.MANAGEMENT}/user/list`,
      view: (username: string) => `${ROOTS.MANAGEMENT}/user/${username}/view`,
      edit: (username: string) => `${ROOTS.MANAGEMENT}/user/${username}/edit`,
      create: `${ROOTS.MANAGEMENT}/user/create`
    },
    curriculum: {
      root: `${ROOTS.MANAGEMENT}/curriculum`,
      list: `${ROOTS.MANAGEMENT}/curriculum/list`,
      view: (curriculumId: string) => `${ROOTS.MANAGEMENT}/curriculum/${curriculumId}/view`,
      edit: (curriculumId: string) => `${ROOTS.MANAGEMENT}/curriculum/${curriculumId}/edit`,
      create: `${ROOTS.MANAGEMENT}/curriculum/create`
    },
    class: {
      root: `${ROOTS.MANAGEMENT}/class`,
      list: `${ROOTS.MANAGEMENT}/class/list`,
      viewWithDefaultTab: (classId: string) => `${ROOTS.MANAGEMENT}/class/${classId}/view`,
      view: (classId: string, tab: TabValue) => `${ROOTS.MANAGEMENT}/class/${classId}/view?tab=${tab}`,
      edit: (classId: string) => `${ROOTS.MANAGEMENT}/class/${classId}/edit`,
      create: `${ROOTS.MANAGEMENT}/class/create`,
    },
    centre: {
      root: `${ROOTS.MANAGEMENT}/centre`,
      list: `${ROOTS.MANAGEMENT}/centre/list`,
      view: (centreId: string) => `${ROOTS.MANAGEMENT}/centre/${centreId}/view`,
      edit: (centreId: string) => `${ROOTS.MANAGEMENT}/centre/${centreId}/edit`,
      create: `${ROOTS.MANAGEMENT}/centre/create`
    },
    classroom: {
      root: `${ROOTS.MANAGEMENT}/classroom`,
      list: `${ROOTS.MANAGEMENT}/classroom/list`,
      view: (classroomId: string) => `${ROOTS.MANAGEMENT}/classroom/${classroomId}/view`,
      edit: (classroomId: string) => `${ROOTS.MANAGEMENT}/classroom/${classroomId}/edit`,
      create: `${ROOTS.MANAGEMENT}/classroom/create`
    },
  }
};
