import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import MainLayout from 'src/layouts/main';

import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const AccountPage = lazy(() => import('src/pages/management/user/account'))
const UsersListPage = lazy(() => import('src/pages/management/user/list'))
const ListClassPage = lazy(() => import('src/pages/management/class/list'))
const ViewClassPage = lazy(() => import('src/pages/management/class/view-class-page'))
const ListClassroomPage = lazy(() => import('src/pages/management/classroom/list'))
const CentresListPage = lazy(() => import('src/pages/management/centre/list'))
const CreateUserPage = lazy(() => import('src/pages/management/user/create'))
const CreateCurriculumPage = lazy(() => import('src/pages/management/curriculum/create'))
const CreateClassPage = lazy(() => import('src/pages/management/class/create'))
const ListCurriculumPage = lazy(() => import('src/pages/management/curriculum/list'))
const ViewCurriculumPage = lazy(() => import('src/pages/management/curriculum/view-curriculum-page'))
const EditCurriculumPage = lazy(() => import('src/pages/management/curriculum/edit'))
const CreateCentrePage = lazy(() => import('src/pages/management/centre/create'))
const CreateClassroomPage = lazy(() => import('src/pages/management/classroom/create'))

// DO NOT CHANGE EASILY - OR CHANGE THE SERVER SIDE AT THE SAME TIME
export const SEARCH_PARAM_SESSION_INDEX = 'sessionIdx'

// ----------------------------------------------------------------------
export const managementRoutes = [
  {
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      {
        children: [
          // user
          { path: paths.management.user.view(':usernameUrlParam'), element: <AccountPage /> },
          { path: paths.management.user.edit(':usernameUrlParam'), element: <AccountPage /> },
          { path: paths.management.user.list, element: <UsersListPage /> },
          { path: paths.management.user.create, element: <CreateUserPage /> },

          // curriculum
          { path: paths.management.curriculum.list, element: <ListCurriculumPage /> },
          { path: paths.management.curriculum.create, element: <CreateCurriculumPage /> },
          { path: paths.management.curriculum.view(':curriculumIdParam'), element: <ViewCurriculumPage /> },
          { path: paths.management.curriculum.edit(':curriculumIdParam'), element: <EditCurriculumPage /> },

          // class
          { path: paths.management.class.list, element: <ListClassPage /> },
          { path: paths.management.class.viewWithDefaultTab(':classIdParam'), element: <ViewClassPage /> },
          { path: paths.management.class.create, element: <CreateClassPage /> },

          // centre
          { path: paths.management.centre.list, element: <CentresListPage /> },
          { path: paths.management.centre.create, element: <CreateCentrePage /> },

          // classroom
          { path: paths.management.classroom.list, element: <ListClassroomPage /> },
          { path: paths.management.classroom.create, element: <CreateClassroomPage /> },
        ]
      },
    ],
  },
];
