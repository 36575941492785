import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { routerActions } from '@lagunovsky/redux-react-router';
import { useCurrentSelectedOrganization, useUserCurrentOrganizationPermission } from 'src/store/authentication/selectedOrganizationHook';
import { OrgRole } from 'src/API';
import { AUTH_TOKEN_QUERY_PARAM, AUTH_TOKEN_USER_NAME } from 'src/utils/authTokenUtils';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  amplify: paths.auth.amplify.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const loggedInUser = useSelector((state: RootState) => state.authentication.loggedInUser)

  const router = useRouter();

  const { authenticated, method, loginUsingAuthToken, switchUserWithAuthToken } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const loggedInUsername = useSelector((state: RootState) => state.authentication.loggedInUser?.userName);

  const check = useCallback(async () => {

    // don't use search params from useSearchParam - somehow the params are not updated after router.replace
    const url = new URL(window.location.href)
    const authTokenId = url.searchParams.get(AUTH_TOKEN_QUERY_PARAM);
    const authTokenUsername = url.searchParams.get(AUTH_TOKEN_USER_NAME);

    if (authenticated) {
      // is using auth token mode
      if (authTokenUsername && authTokenId) {
        // logged in user is different -> should log out then re-login
        if (authTokenUsername.toLocaleLowerCase() !== loggedInUsername?.toLocaleLowerCase()) {
          const result = await switchUserWithAuthToken(authTokenId)
          if (result) {
            setChecked(true)
            return
          }
        }
        else {
          setChecked(true);
          return
        }
      }
      else {
        setChecked(true);
        return
      }
    }
    // unauthenticated yet, but has auth token
    else if (authTokenUsername && authTokenId) {
      console.log(window.location.href)
      console.log('login using auth token: ' + authTokenId)
      const result = await loginUsingAuthToken(authTokenId)
      if (result) {
        setChecked(true);
        return
      }
    }

    // fallback to login
    const searchParamsNew = new URLSearchParams({
      returnTo: `${window.location.pathname}${window.location.search}`,
    }).toString();

    const loginPath = loginPaths[method];

    const href = `${loginPath}?${searchParamsNew}`;

    console.log('fall back to login: ' + href)

    router.replace(href);
  }, [authenticated, loggedInUsername, loginUsingAuthToken, method, router, switchUserWithAuthToken]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) {
    return null;
  }

  return loggedInUser ? <>{children}</> : <SplashScreen />;
}