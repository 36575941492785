/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGlobalConfigurationsInput = {
  id?: string | null,
  latestAppVersion: string,
  miniApps?: Array< LyzaMiniAppInput > | null,
};

export type LyzaMiniAppInput = {
  id: string,
  type: LyzaMiniAppType,
  timestamp: string,
};

export enum LyzaMiniAppType {
  SCAN_TO_CODE = "SCAN_TO_CODE",
  ATTENDANCE_REGISTRATION = "ATTENDANCE_REGISTRATION",
  SCIENCE_CENTRE_AMBASSADOR = "SCIENCE_CENTRE_AMBASSADOR",
  FACE_REGISTRATION = "FACE_REGISTRATION",
  SING_AND_DANCE = "SING_AND_DANCE",
}


export type ModelGlobalConfigurationsConditionInput = {
  latestAppVersion?: ModelStringInput | null,
  and?: Array< ModelGlobalConfigurationsConditionInput | null > | null,
  or?: Array< ModelGlobalConfigurationsConditionInput | null > | null,
  not?: ModelGlobalConfigurationsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type GlobalConfigurations = {
  __typename: "GlobalConfigurations",
  id: string,
  latestAppVersion: string,
  miniApps?:  Array<LyzaMiniApp > | null,
  createdAt: string,
  updatedAt: string,
};

export type LyzaMiniApp = {
  __typename: "LyzaMiniApp",
  id: string,
  type: LyzaMiniAppType,
  timestamp: string,
};

export type UpdateGlobalConfigurationsInput = {
  id: string,
  latestAppVersion?: string | null,
  miniApps?: Array< LyzaMiniAppInput > | null,
};

export type DeleteGlobalConfigurationsInput = {
  id: string,
};

export type CreateRobotActionInput = {
  organizationID: string,
  actionID: string,
  actionName: string,
  actionNameEmbedding: Array< number >,
  embeddingModel: EmbeddingModel,
  textToSayEn?: string | null,
  textToSayZh?: string | null,
  actionBlocklyProjectId?: string | null,
  actionBlocklyProjectVersion?: string | null,
};

export enum EmbeddingModel {
  OPEN_AI_TEXT_EMBEDDING_3_SMALL = "OPEN_AI_TEXT_EMBEDDING_3_SMALL",
}


export type ModelRobotActionConditionInput = {
  actionName?: ModelStringInput | null,
  actionNameEmbedding?: ModelFloatInput | null,
  embeddingModel?: ModelEmbeddingModelInput | null,
  textToSayEn?: ModelStringInput | null,
  textToSayZh?: ModelStringInput | null,
  actionBlocklyProjectId?: ModelIDInput | null,
  actionBlocklyProjectVersion?: ModelStringInput | null,
  and?: Array< ModelRobotActionConditionInput | null > | null,
  or?: Array< ModelRobotActionConditionInput | null > | null,
  not?: ModelRobotActionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelEmbeddingModelInput = {
  eq?: EmbeddingModel | null,
  ne?: EmbeddingModel | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type RobotAction = {
  __typename: "RobotAction",
  organizationID: string,
  actionID: string,
  actionName: string,
  actionNameEmbedding: Array< number >,
  embeddingModel: EmbeddingModel,
  textToSayEn?: string | null,
  textToSayZh?: string | null,
  actionBlocklyProjectId?: string | null,
  actionBlocklyProjectVersion?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRobotActionInput = {
  organizationID: string,
  actionID: string,
  actionName?: string | null,
  actionNameEmbedding?: Array< number > | null,
  embeddingModel?: EmbeddingModel | null,
  textToSayEn?: string | null,
  textToSayZh?: string | null,
  actionBlocklyProjectId?: string | null,
  actionBlocklyProjectVersion?: string | null,
};

export type DeleteRobotActionInput = {
  organizationID: string,
  actionID: string,
};

export type CreateAudioRecordingInput = {
  s3bucketKey: string,
  whisperResult?: string | null,
  googleSpeechToText?: string | null,
  awsTranscribeResult?: string | null,
};

export type ModelAudioRecordingConditionInput = {
  whisperResult?: ModelStringInput | null,
  googleSpeechToText?: ModelStringInput | null,
  awsTranscribeResult?: ModelStringInput | null,
  and?: Array< ModelAudioRecordingConditionInput | null > | null,
  or?: Array< ModelAudioRecordingConditionInput | null > | null,
  not?: ModelAudioRecordingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AudioRecording = {
  __typename: "AudioRecording",
  s3bucketKey: string,
  whisperResult?: string | null,
  googleSpeechToText?: string | null,
  awsTranscribeResult?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAudioRecordingInput = {
  s3bucketKey: string,
  whisperResult?: string | null,
  googleSpeechToText?: string | null,
  awsTranscribeResult?: string | null,
};

export type DeleteAudioRecordingInput = {
  s3bucketKey: string,
};

export type CreateConversationHistoryInput = {
  deviceID: string,
  startDateTime: string,
  messages: Array< MessageInput >,
};

export type MessageInput = {
  role: Role,
  content: string,
};

export enum Role {
  USER = "USER",
  KEBBI_FAQ = "KEBBI_FAQ",
  KEBBI_GPT = "KEBBI_GPT",
}


export type ModelConversationHistoryConditionInput = {
  and?: Array< ModelConversationHistoryConditionInput | null > | null,
  or?: Array< ModelConversationHistoryConditionInput | null > | null,
  not?: ModelConversationHistoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ConversationHistory = {
  __typename: "ConversationHistory",
  deviceID: string,
  startDateTime: string,
  messages:  Array<Message >,
  createdAt: string,
  updatedAt: string,
};

export type Message = {
  __typename: "Message",
  role: Role,
  content: string,
};

export type UpdateConversationHistoryInput = {
  deviceID: string,
  startDateTime: string,
  messages?: Array< MessageInput > | null,
};

export type DeleteConversationHistoryInput = {
  deviceID: string,
  startDateTime: string,
};

export type CreateQuestionAndAnswerRecordsV2Input = {
  deviceID: string,
  dateTime: string,
  source: string,
  question: string,
  answer: string,
};

export type ModelQuestionAndAnswerRecordsV2ConditionInput = {
  source?: ModelStringInput | null,
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  and?: Array< ModelQuestionAndAnswerRecordsV2ConditionInput | null > | null,
  or?: Array< ModelQuestionAndAnswerRecordsV2ConditionInput | null > | null,
  not?: ModelQuestionAndAnswerRecordsV2ConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type QuestionAndAnswerRecordsV2 = {
  __typename: "QuestionAndAnswerRecordsV2",
  deviceID: string,
  dateTime: string,
  source: string,
  question: string,
  answer: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateQuestionAndAnswerRecordsV2Input = {
  deviceID: string,
  dateTime: string,
  source?: string | null,
  question?: string | null,
  answer?: string | null,
};

export type DeleteQuestionAndAnswerRecordsV2Input = {
  deviceID: string,
  dateTime: string,
};

export type CreateAppLoggingInput = {
  userID: string,
  dateTime: string,
  namespace?: string | null,
  message?: string | null,
  stacktrace?: string | null,
};

export type ModelAppLoggingConditionInput = {
  namespace?: ModelStringInput | null,
  message?: ModelStringInput | null,
  stacktrace?: ModelStringInput | null,
  and?: Array< ModelAppLoggingConditionInput | null > | null,
  or?: Array< ModelAppLoggingConditionInput | null > | null,
  not?: ModelAppLoggingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AppLogging = {
  __typename: "AppLogging",
  userID: string,
  dateTime: string,
  namespace?: string | null,
  message?: string | null,
  stacktrace?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAppLoggingInput = {
  userID: string,
  dateTime: string,
  namespace?: string | null,
  message?: string | null,
  stacktrace?: string | null,
};

export type DeleteAppLoggingInput = {
  userID: string,
  dateTime: string,
};

export type CreateOrganizationDataInput = {
  organizationID: string,
  name: string,
  compreFaceFaceRecognitionApiKey?: string | null,
  usePaidSpeechToText?: boolean | null,
  usePaidTextToSpeech?: boolean | null,
  miniApps?: Array< LyzaMiniAppInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isSchoolManagementEnabled?: boolean | null,
  isKebbiForBusinessEnabled?: boolean | null,
  allowedOrgRoles?: Array< OrgRole > | null,
};

export enum OrgRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  STUDENT = "STUDENT",
  TEACHER_FULL_TIME = "TEACHER_FULL_TIME",
  TEACHER_PART_TIME = "TEACHER_PART_TIME",
  USER = "USER",
}


export type ModelOrganizationDataConditionInput = {
  name?: ModelStringInput | null,
  compreFaceFaceRecognitionApiKey?: ModelStringInput | null,
  usePaidSpeechToText?: ModelBooleanInput | null,
  usePaidTextToSpeech?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isSchoolManagementEnabled?: ModelBooleanInput | null,
  isKebbiForBusinessEnabled?: ModelBooleanInput | null,
  allowedOrgRoles?: ModelOrgRoleListInput | null,
  and?: Array< ModelOrganizationDataConditionInput | null > | null,
  or?: Array< ModelOrganizationDataConditionInput | null > | null,
  not?: ModelOrganizationDataConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelOrgRoleListInput = {
  eq?: Array< OrgRole | null > | null,
  ne?: Array< OrgRole | null > | null,
  contains?: OrgRole | null,
  notContains?: OrgRole | null,
};

export type OrganizationData = {
  __typename: "OrganizationData",
  organizationID: string,
  name: string,
  compreFaceFaceRecognitionApiKey?: string | null,
  usePaidSpeechToText?: boolean | null,
  usePaidTextToSpeech?: boolean | null,
  miniApps?:  Array<LyzaMiniApp > | null,
  createdAt: string,
  updatedAt: string,
  isSchoolManagementEnabled?: boolean | null,
  isKebbiForBusinessEnabled?: boolean | null,
  allowedOrgRoles?: Array< OrgRole > | null,
};

export type UpdateOrganizationDataInput = {
  organizationID: string,
  name?: string | null,
  compreFaceFaceRecognitionApiKey?: string | null,
  usePaidSpeechToText?: boolean | null,
  usePaidTextToSpeech?: boolean | null,
  miniApps?: Array< LyzaMiniAppInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isSchoolManagementEnabled?: boolean | null,
  isKebbiForBusinessEnabled?: boolean | null,
  allowedOrgRoles?: Array< OrgRole > | null,
};

export type DeleteOrganizationDataInput = {
  organizationID: string,
};

export type CreateFaceRecognitionMomentsForImprovementsLogInput = {
  id?: string | null,
  kebbiDeviceId: string,
  organizationId: string,
  recognitionHistory: Array< CompreFaceRecognitionResultsInput >,
  outcome?: FaceRecognitionLogOutcome | null,
  logReason: FaceRecognitionLogReason,
  recognizedUser?: string | null,
};

export type CompreFaceRecognitionResultsInput = {
  results: Array< CompreFaceRecognitionResultInput >,
};

export type CompreFaceRecognitionResultInput = {
  similarity: number,
  subject: string,
};

export enum FaceRecognitionLogOutcome {
  RECOGNIZED_BY_REKOGNITION = "RECOGNIZED_BY_REKOGNITION",
  USER_LIKELY_NOT_REGISTERED = "USER_LIKELY_NOT_REGISTERED",
  COMPREFACE_RECOGNIZED_BY_HIGH_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_HIGH_CONFIDENCE",
  COMPREFACE_RECOGNIZED_BY_MID_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_MID_CONFIDENCE",
  COMPREFACE_RECOGNIZED_BY_LOW_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_LOW_CONFIDENCE",
}


export enum FaceRecognitionLogReason {
  RECOGNIZED_BY_REKOGNITION = "RECOGNIZED_BY_REKOGNITION",
  USER_LIKELY_NOT_REGISTERED = "USER_LIKELY_NOT_REGISTERED",
  REKOGNITION_START_BY_MULTI_HIGH_CONFIDENCE_RESULTS = "REKOGNITION_START_BY_MULTI_HIGH_CONFIDENCE_RESULTS",
  REKOGNITION_START_BY_CONTINUOUS_DETECTION_1_COUNTS = "REKOGNITION_START_BY_CONTINUOUS_DETECTION_1_COUNTS",
  REKOGNITION_START_BY_CONTINUOUS_DETECTION_2_COUNTS = "REKOGNITION_START_BY_CONTINUOUS_DETECTION_2_COUNTS",
  COMPREFACE_RECOGNIZED_BY_HIGH_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_HIGH_CONFIDENCE",
  COMPREFACE_RECOGNIZED_BY_MID_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_MID_CONFIDENCE",
  COMPREFACE_RECOGNIZED_BY_LOW_CONFIDENCE = "COMPREFACE_RECOGNIZED_BY_LOW_CONFIDENCE",
  COMPREFACE = "COMPREFACE",
}


export type ModelFaceRecognitionMomentsForImprovementsLogConditionInput = {
  kebbiDeviceId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  outcome?: ModelFaceRecognitionLogOutcomeInput | null,
  logReason?: ModelFaceRecognitionLogReasonInput | null,
  recognizedUser?: ModelStringInput | null,
  and?: Array< ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null > | null,
  or?: Array< ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null > | null,
  not?: ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFaceRecognitionLogOutcomeInput = {
  eq?: FaceRecognitionLogOutcome | null,
  ne?: FaceRecognitionLogOutcome | null,
};

export type ModelFaceRecognitionLogReasonInput = {
  eq?: FaceRecognitionLogReason | null,
  ne?: FaceRecognitionLogReason | null,
};

export type FaceRecognitionMomentsForImprovementsLog = {
  __typename: "FaceRecognitionMomentsForImprovementsLog",
  id: string,
  kebbiDeviceId: string,
  organizationId: string,
  recognitionHistory:  Array<CompreFaceRecognitionResults >,
  outcome?: FaceRecognitionLogOutcome | null,
  logReason: FaceRecognitionLogReason,
  recognizedUser?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CompreFaceRecognitionResults = {
  __typename: "CompreFaceRecognitionResults",
  results:  Array<CompreFaceRecognitionResult >,
};

export type CompreFaceRecognitionResult = {
  __typename: "CompreFaceRecognitionResult",
  similarity: number,
  subject: string,
};

export type UpdateFaceRecognitionMomentsForImprovementsLogInput = {
  id: string,
  kebbiDeviceId?: string | null,
  organizationId?: string | null,
  recognitionHistory?: Array< CompreFaceRecognitionResultsInput > | null,
  outcome?: FaceRecognitionLogOutcome | null,
  logReason?: FaceRecognitionLogReason | null,
  recognizedUser?: string | null,
};

export type DeleteFaceRecognitionMomentsForImprovementsLogInput = {
  id: string,
};

export type CreateKebbiDeviceInput = {
  id?: string | null,
  organizationID?: string | null,
  cognitoIdentityID: string,
  cognitoUserID: string,
  password: string,
  deviceServiceStatus: Array< DeviceServiceStatusInput >,
  registeredFaces?: Array< KebbiRegisteredFaceInput > | null,
  version?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isTestDevice?: boolean | null,
};

export type DeviceServiceStatusInput = {
  serviceName: ServiceName,
  expirationDate?: string | null,
};

export enum ServiceName {
  CARD_SCANNING_BASIC = "CARD_SCANNING_BASIC",
  CARD_SCANNING_CUSTOMIZE = "CARD_SCANNING_CUSTOMIZE",
  CODE_LAB_BASIC = "CODE_LAB_BASIC",
  FACE_RECOGNITION = "FACE_RECOGNITION",
}


export type KebbiRegisteredFaceInput = {
  file?: Array< FileTypeInput > | null,
  fullName: string,
  preferredName: string,
};

export type FileTypeInput = {
  lastUpdatedTimestamp?: string | null,
  name: string,
  category: FileCategory,
  identityId?: string | null,
};

export enum FileCategory {
  PROJECT_PROFILE_IMAGE = "PROJECT_PROFILE_IMAGE",
  PROJECT_IMAGE = "PROJECT_IMAGE",
  PROJECT_VIDEO = "PROJECT_VIDEO",
  PROJECT_TEACHABLE_MACHINE = "PROJECT_TEACHABLE_MACHINE",
  PROJECT_AUDIO = "PROJECT_AUDIO",
  KEBBI_REGISTERED_FACE = "KEBBI_REGISTERED_FACE",
}


export type ModelKebbiDeviceConditionInput = {
  organizationID?: ModelStringInput | null,
  cognitoIdentityID?: ModelStringInput | null,
  cognitoUserID?: ModelStringInput | null,
  password?: ModelStringInput | null,
  version?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isTestDevice?: ModelBooleanInput | null,
  and?: Array< ModelKebbiDeviceConditionInput | null > | null,
  or?: Array< ModelKebbiDeviceConditionInput | null > | null,
  not?: ModelKebbiDeviceConditionInput | null,
  owner?: ModelStringInput | null,
};

export type KebbiDevice = {
  __typename: "KebbiDevice",
  id: string,
  organizationID?: string | null,
  cognitoIdentityID: string,
  cognitoUserID: string,
  password: string,
  deviceServiceStatus:  Array<DeviceServiceStatus >,
  registeredFaces?:  Array<KebbiRegisteredFace > | null,
  version?: string | null,
  createdAt: string,
  updatedAt: string,
  isTestDevice?: boolean | null,
  owner?: string | null,
};

export type DeviceServiceStatus = {
  __typename: "DeviceServiceStatus",
  serviceName: ServiceName,
  expirationDate?: string | null,
};

export type KebbiRegisteredFace = {
  __typename: "KebbiRegisteredFace",
  file?:  Array<FileType > | null,
  fullName: string,
  preferredName: string,
};

export type FileType = {
  __typename: "FileType",
  lastUpdatedTimestamp?: string | null,
  name: string,
  category: FileCategory,
  identityId?: string | null,
};

export type UpdateKebbiDeviceInput = {
  id: string,
  organizationID?: string | null,
  cognitoIdentityID?: string | null,
  cognitoUserID?: string | null,
  password?: string | null,
  deviceServiceStatus?: Array< DeviceServiceStatusInput > | null,
  registeredFaces?: Array< KebbiRegisteredFaceInput > | null,
  version?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isTestDevice?: boolean | null,
};

export type DeleteKebbiDeviceInput = {
  id: string,
};

export type CreateOrganizationUserInput = {
  organizationID: string,
  username: string,
  status: OrganizationUserStatus,
  role: OrgRole,
  createdAt?: string | null,
  updatedAt?: string | null,
  isShadowAdmin?: boolean | null,
};

export enum OrganizationUserStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DEACTIVATED = "DEACTIVATED",
}


export type ModelOrganizationUserConditionInput = {
  status?: ModelOrganizationUserStatusInput | null,
  role?: ModelOrgRoleInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isShadowAdmin?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationUserConditionInput | null > | null,
  or?: Array< ModelOrganizationUserConditionInput | null > | null,
  not?: ModelOrganizationUserConditionInput | null,
};

export type ModelOrganizationUserStatusInput = {
  eq?: OrganizationUserStatus | null,
  ne?: OrganizationUserStatus | null,
};

export type ModelOrgRoleInput = {
  eq?: OrgRole | null,
  ne?: OrgRole | null,
};

export type OrganizationUser = {
  __typename: "OrganizationUser",
  organizationID: string,
  username: string,
  status: OrganizationUserStatus,
  role: OrgRole,
  createdAt: string,
  updatedAt: string,
  isShadowAdmin?: boolean | null,
};

export type UpdateOrganizationUserInput = {
  organizationID: string,
  username: string,
  status?: OrganizationUserStatus | null,
  role?: OrgRole | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isShadowAdmin?: boolean | null,
};

export type DeleteOrganizationUserInput = {
  organizationID: string,
  username: string,
};

export type CreateUserInput = {
  userName: string,
  cognitoIdentityID: string,
  password?: string | null,
  organizations?: Array< string > | null,
  legalFullName?: string | null,
  preferredName?: string | null,
  email?: string | null,
};

export type ModelUserConditionInput = {
  cognitoIdentityID?: ModelStringInput | null,
  password?: ModelStringInput | null,
  organizations?: ModelIDInput | null,
  parentName?: ModelStringInput | null,
  legalFullName?: ModelStringInput | null,
  preferredName?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  about?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type User = {
  __typename: "User",
  userName: string,
  cognitoIdentityID?: string | null,
  password?: string | null,
  organizations?: Array< string > | null,
  profileImage?: S3File | null,
  parentName?: string | null,
  legalFullName?: string | null,
  preferredName?: string | null,
  country?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  dateOfBirth?: string | null,
  about?: string | null,
  notes?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type S3File = {
  __typename: "S3File",
  s3Key: string,
  versionNumber: number,
  documentType: DocumentType,
};

export enum DocumentType {
  CLASS_CERTIFICATE = "CLASS_CERTIFICATE",
  CURRICULUM_PHOTO_VIDEO = "CURRICULUM_PHOTO_VIDEO",
  SESSION_PHOTO_VIDEO = "SESSION_PHOTO_VIDEO",
  USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE",
  MACHINE_LEARNING_CLASS = "MACHINE_LEARNING_CLASS",
  WHATSAPP_FILE = "WHATSAPP_FILE",
}


export type UpdateUserInput = {
  userName: string,
  cognitoIdentityID?: string | null,
  password?: string | null,
  organizations?: Array< string > | null,
  profileImage?: S3FileInput | null,
  parentName?: string | null,
  legalFullName?: string | null,
  preferredName?: string | null,
  country?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  dateOfBirth?: string | null,
  about?: string | null,
  notes?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type S3FileInput = {
  s3Key: string,
  versionNumber: number,
  documentType: DocumentType,
};

export type DeleteUserInput = {
  userName: string,
};

export type CreateTempAuthTokenInput = {
  id?: string | null,
  username: string,
  expirationAt: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTempAuthTokenConditionInput = {
  username?: ModelStringInput | null,
  expirationAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTempAuthTokenConditionInput | null > | null,
  or?: Array< ModelTempAuthTokenConditionInput | null > | null,
  not?: ModelTempAuthTokenConditionInput | null,
};

export type TempAuthToken = {
  __typename: "TempAuthToken",
  id: string,
  username: string,
  expirationAt: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTempAuthTokenInput = {
  id: string,
  username?: string | null,
  expirationAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTempAuthTokenInput = {
  id: string,
};

export type CreateActivationCodeInput = {
  id?: string | null,
  durationInDays: number,
  serviceNames: Array< ServiceName >,
};

export type ModelActivationCodeConditionInput = {
  durationInDays?: ModelIntInput | null,
  serviceNames?: ModelServiceNameInput | null,
  and?: Array< ModelActivationCodeConditionInput | null > | null,
  or?: Array< ModelActivationCodeConditionInput | null > | null,
  not?: ModelActivationCodeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelServiceNameInput = {
  eq?: ServiceName | null,
  ne?: ServiceName | null,
};

export type ActivationCode = {
  __typename: "ActivationCode",
  id: string,
  durationInDays: number,
  serviceNames: Array< ServiceName >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateActivationCodeInput = {
  id: string,
  durationInDays?: number | null,
  serviceNames?: Array< ServiceName > | null,
};

export type DeleteActivationCodeInput = {
  id: string,
};

export type CreateKebbiMaterialsInput = {
  materialType: KebbiMaterialType,
  page: number,
  materials: Array< KebbiMaterialInput >,
};

export enum KebbiMaterialType {
  MOTION = "MOTION",
}


export type KebbiMaterialInput = {
  title: string,
  i18n: I18nInput,
  identityID: string,
  fileName: string,
};

export type I18nInput = {
  en_US: string,
};

export type ModelKebbiMaterialsConditionInput = {
  and?: Array< ModelKebbiMaterialsConditionInput | null > | null,
  or?: Array< ModelKebbiMaterialsConditionInput | null > | null,
  not?: ModelKebbiMaterialsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type KebbiMaterials = {
  __typename: "KebbiMaterials",
  materialType: KebbiMaterialType,
  page: number,
  materials:  Array<KebbiMaterial >,
  createdAt: string,
  updatedAt: string,
};

export type KebbiMaterial = {
  __typename: "KebbiMaterial",
  title: string,
  i18n: i18n,
  identityID: string,
  fileName: string,
};

export type i18n = {
  __typename: "i18n",
  en_US: string,
};

export type UpdateKebbiMaterialsInput = {
  materialType: KebbiMaterialType,
  page: number,
  materials?: Array< KebbiMaterialInput > | null,
};

export type DeleteKebbiMaterialsInput = {
  materialType: KebbiMaterialType,
  page: number,
};

export type CreateBlocklyProjectInput = {
  id?: string | null,
  groupID?: string | null,
  workspaceJson: string,
  projectName: string,
  identityID: string,
  files: Array< FileTypeInput >,
  filesV2?: Array< S3FileInput > | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  machineLearningProjectIDs?: Array< string > | null,
};

export type ModelBlocklyProjectConditionInput = {
  groupID?: ModelStringInput | null,
  workspaceJson?: ModelStringInput | null,
  projectName?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  machineLearningProjectIDs?: ModelIDInput | null,
  and?: Array< ModelBlocklyProjectConditionInput | null > | null,
  or?: Array< ModelBlocklyProjectConditionInput | null > | null,
  not?: ModelBlocklyProjectConditionInput | null,
};

export type BlocklyProject = {
  __typename: "BlocklyProject",
  id: string,
  groupID?: string | null,
  workspaceJson: string,
  projectName: string,
  identityID: string,
  files:  Array<FileType >,
  filesV2?:  Array<S3File > | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  machineLearningProjectIDs?: Array< string > | null,
};

export type UpdateBlocklyProjectInput = {
  id: string,
  groupID?: string | null,
  workspaceJson?: string | null,
  projectName?: string | null,
  identityID?: string | null,
  files?: Array< FileTypeInput > | null,
  filesV2?: Array< S3FileInput > | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  machineLearningProjectIDs?: Array< string > | null,
};

export type DeleteBlocklyProjectInput = {
  id: string,
};

export type CreateUserSubscriptionEventStatusInput = {
  userID: string,
  machineLearning?: MachineLearningStatusInput | null,
};

export type MachineLearningStatusInput = {
  tfjsToTfliteConversionStatus?: ProcessStatus | null,
};

export enum ProcessStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type ModelUserSubscriptionEventStatusConditionInput = {
  and?: Array< ModelUserSubscriptionEventStatusConditionInput | null > | null,
  or?: Array< ModelUserSubscriptionEventStatusConditionInput | null > | null,
  not?: ModelUserSubscriptionEventStatusConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UserSubscriptionEventStatus = {
  __typename: "UserSubscriptionEventStatus",
  userID: string,
  machineLearning?: MachineLearningStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type MachineLearningStatus = {
  __typename: "MachineLearningStatus",
  tfjsToTfliteConversionStatus?: ProcessStatus | null,
};

export type UpdateUserSubscriptionEventStatusInput = {
  userID: string,
  machineLearning?: MachineLearningStatusInput | null,
};

export type DeleteUserSubscriptionEventStatusInput = {
  userID: string,
};

export type UpdateUserOrganizationDetailsResult = {
  __typename: "UpdateUserOrganizationDetailsResult",
  user: User,
  organizationUser: OrganizationUser,
};

export type CreateUserByOrganizationAdminResult = {
  __typename: "CreateUserByOrganizationAdminResult",
  isUsernameAlreadyExistError: boolean,
  user?: UserWithOrgData | null,
};

export type UserWithOrgData = {
  __typename: "UserWithOrgData",
  user: User,
  organizations:  Array<OrganizationDataForUser >,
};

export type OrganizationDataForUser = {
  __typename: "OrganizationDataForUser",
  userOrg: OrganizationUser,
  orgName: string,
  userOrgPermission: UserOrgPermission,
  allowedOrgRoles: Array< OrgRole >,
};

export type UserOrgPermission = {
  __typename: "UserOrgPermission",
  canReadUsers: boolean,
  canCreateUser: boolean,
  canListCurriculums: boolean,
  canCreateCurriculum: boolean,
  canListClasses: boolean,
  canCreateClass: boolean,
  canListCentres: boolean,
  canCreateCentres: boolean,
  canListClassrooms: boolean,
  canCreateClassrooms: boolean,
  isKebbiForBusinessEnabled: boolean,
  isCodelabProEnabled: boolean,
};

export type CreateRobotFAQInputs = {
  organizationID: string,
  robotFAQs: Array< CreateRobotFAQInput >,
};

export type CreateRobotFAQInput = {
  question: string,
  textToSayEn: string,
  textToSayZh: string,
};

export type RobotFAQ = {
  __typename: "RobotFAQ",
  organizationID: string,
  question: string,
  questionEmbedding: Array< number >,
  embeddingModel: EmbeddingModel,
  textToSayEn: string,
  textToSayZh: string,
  createdAt: string,
  updatedAt: string,
};

export enum OwnerType {
  USERNAME = "USERNAME",
  GROUP = "GROUP",
  ORGANIZATION = "ORGANIZATION",
}


export enum MachineLearningModelType {
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
}


export type MachineLearningProject = {
  __typename: "MachineLearningProject",
  id: string,
  ownerID: string,
  ownerType: OwnerType,
  projectName: string,
  modelType: MachineLearningModelType,
  modelS3RootDirectory: string,
  classes:  Array<MachineLearningClass >,
  targetLatestModelVersion?: number | null,
  tfjsModelVersion?: number | null,
  tfliteModelVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  blocklyProjectIDs?: Array< string > | null,
};

export type MachineLearningClass = {
  __typename: "MachineLearningClass",
  id: string,
  name: string,
  samplesZipS3File: S3File,
  isDisabled: boolean,
};

export type MachineLearningClassUpdate = {
  id: string,
  name: string,
  isDisabled: boolean,
};

export type UpdateMachineLearningProjectOutput = {
  __typename: "UpdateMachineLearningProjectOutput",
  updatedProject: MachineLearningProject,
  getUploadURLsForMLTrainingOutput?: GetUploadURLsForMLTrainingOutput | null,
};

export type GetUploadURLsForMLTrainingOutput = {
  __typename: "GetUploadURLsForMLTrainingOutput",
  modelJsonUrl: string,
  modelWeightsUrl: string,
  modelLabelUrl: string,
  classSamplesUrls: Array< string >,
};

export enum Languages {
  PYTHON = "PYTHON",
}


export type CodespaceProject = {
  __typename: "CodespaceProject",
  id: string,
  ownerID: string,
  ownerType: OwnerType,
  projectName: string,
  language: Languages,
  code: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCodespaceProjectOutput = {
  __typename: "UpdateCodespaceProjectOutput",
  updatedProject: CodespaceProject,
};

export type CentreDataInput = {
  name: string,
  address: string,
  postcode: string,
  country: string,
};

export enum ItemStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}


export type Centre = {
  __typename: "Centre",
  id: string,
  organizationID: string,
  data: CentreData,
  status: ItemStatus,
  createdAt: string,
  updatedAt: string,
};

export type CentreData = {
  __typename: "CentreData",
  name: string,
  address: string,
  postcode: string,
  country: string,
};

export type ClassroomDataInput = {
  centreId: string,
  centre: CentreDataInput,
  name: string,
};

export type Classroom = {
  __typename: "Classroom",
  id: string,
  organizationID: string,
  data: ClassroomData,
  status: ItemStatus,
  createdAt: string,
  updatedAt: string,
};

export type ClassroomData = {
  __typename: "ClassroomData",
  centreId: string,
  centre: CentreData,
  name: string,
};

export type CurriculumDataInput = {
  name: string,
  description: string,
  covers: Array< S3FileInput >,
  type: CurriculumType,
  sessionTemplates: Array< CurriculumSessionTemplateInput >,
  endOfClassEvaluationsTemplate: Array< EvaluationItemInput >,
  requiredClassLevelCommunications: Array< StudentCommunicationType >,
};

export enum CurriculumType {
  HOLIDAY_CAMP = "HOLIDAY_CAMP",
  TERM_CLASS = "TERM_CLASS",
}


export type CurriculumSessionTemplateInput = {
  sessionName: string,
  sessionDescription: string,
  sessionDurationInMinutes: number,
  evaluationItems: Array< EvaluationItemInput >,
  requireSessionPhotoOrVideoFromTeacher: boolean,
  requireSessionSummaryFromTeacher: boolean,
  requireEachStudentPhotoOrVideoFromTeacher: boolean,
  requiredCommunications: Array< StudentCommunicationType >,
};

export type EvaluationItemInput = {
  metric: EvaluationMetric,
  formats: Array< EvaluationMetricFormatValueInput >,
};

export enum EvaluationMetric {
  APPLICATION_AND_UNDERSTANDING = "APPLICATION_AND_UNDERSTANDING",
  CRITICAL_THINKING = "CRITICAL_THINKING",
  ENGAGEMENT = "ENGAGEMENT",
  SKILLS = "SKILLS",
  GENERAL_FEEDBACK = "GENERAL_FEEDBACK",
}


export type EvaluationMetricFormatValueInput = {
  format: EvaluationMetricFormat,
  required: boolean,
};

export enum EvaluationMetricFormat {
  STAR = "STAR",
  TEXT = "TEXT",
}


export enum StudentCommunicationType {
  SESSION_REMINDER = "SESSION_REMINDER",
  SESSION_PROGRESS_UPDATE = "SESSION_PROGRESS_UPDATE",
  END_OF_COURSE_EVALUATION = "END_OF_COURSE_EVALUATION",
}


export type Curriculum = {
  __typename: "Curriculum",
  id: string,
  organizationID: string,
  data: CurriculumData,
  status: ItemStatus,
  createdAt: string,
  updatedAt: string,
};

export type CurriculumData = {
  __typename: "CurriculumData",
  name: string,
  description: string,
  covers:  Array<S3File >,
  type: CurriculumType,
  sessionTemplates:  Array<CurriculumSessionTemplate >,
  endOfClassEvaluationsTemplate:  Array<EvaluationItem >,
  requiredClassLevelCommunications: Array< StudentCommunicationType >,
};

export type CurriculumSessionTemplate = {
  __typename: "CurriculumSessionTemplate",
  sessionName: string,
  sessionDescription: string,
  sessionDurationInMinutes: number,
  evaluationItems:  Array<EvaluationItem >,
  requireSessionPhotoOrVideoFromTeacher: boolean,
  requireSessionSummaryFromTeacher: boolean,
  requireEachStudentPhotoOrVideoFromTeacher: boolean,
  requiredCommunications: Array< StudentCommunicationType >,
};

export type EvaluationItem = {
  __typename: "EvaluationItem",
  metric: EvaluationMetric,
  formats:  Array<EvaluationMetricFormatValue >,
};

export type EvaluationMetricFormatValue = {
  __typename: "EvaluationMetricFormatValue",
  format: EvaluationMetricFormat,
  required: boolean,
};

export type UpdateCurriculumDataOutput = {
  __typename: "UpdateCurriculumDataOutput",
  actionableErrorMsg?: string | null,
  updatedCurriculum?: Curriculum | null,
  updatedClasses?:  Array<UpdatedClassAndStudentRecords > | null,
};

export type UpdatedClassAndStudentRecords = {
  __typename: "UpdatedClassAndStudentRecords",
  updatedClass: ClassObject,
  updatedClassStudentRecords:  Array<ClassStudentRecord >,
};

export type ClassObject = {
  __typename: "ClassObject",
  id: string,
  organizationID: string,
  curriculumId: string,
  curriculum: CurriculumData,
  sessions:  Array<Session >,
  classLevelSharedFiles:  Array<S3File >,
  classCommunicationOption: ClassCommunicationOption,
  status: ItemStatus,
  createdAt: string,
  updatedAt: string,
};

export type Session = {
  __typename: "Session",
  sessionLevelSharedFiles:  Array<S3File >,
  sessionSummary?: string | null,
  startDateTime: string,
  classroom: ClassroomData,
};

export type ClassCommunicationOption = {
  __typename: "ClassCommunicationOption",
  isSendToParent: boolean,
  sendToNonParentEmail?: string | null,
  sendToNonParentPhoneNumber?: string | null,
};

export type ClassStudentRecord = {
  __typename: "ClassStudentRecord",
  id: string,
  organizationID: string,
  username: string,
  classID: string,
  sessionRecords:  Array<SessionRecordForStudent >,
  endOfClassEvaluations:  Array<EvaluationItemWithResults >,
  sentClassLevelCommunications: Array< StudentCommunicationType >,
  documents:  Array<S3File >,
  createdAt: string,
  updatedAt: string,
};

export type SessionRecordForStudent = {
  __typename: "SessionRecordForStudent",
  isAssigned: boolean,
  attended?: boolean | null,
  notAttendedReason?: string | null,
  documents:  Array<S3File >,
  sessionEvaluations:  Array<EvaluationItemWithResults >,
  sentCommunications: Array< StudentCommunicationType >,
  updatedAt: string,
  updatedByUsername: string,
};

export type EvaluationItemWithResults = {
  __typename: "EvaluationItemWithResults",
  metric: EvaluationMetric,
  results:  Array<EvaluationMetricFormatResult >,
};

export type EvaluationMetricFormatResult = {
  __typename: "EvaluationMetricFormatResult",
  format: EvaluationMetricFormat,
  required: boolean,
  result?: string | null,
};

export type CreateClassInput = {
  curriculumId: string,
  sessions: Array< SessionInput >,
  classLevelSharedFiles: Array< S3FileInput >,
  status: ItemStatus,
  classCommunicationOption: ClassCommunicationOptionInput,
};

export type SessionInput = {
  sessionLevelSharedFiles: Array< S3FileInput >,
  sessionSummary?: string | null,
  startDateTime: string,
  classroom: ClassroomDataInput,
};

export type ClassCommunicationOptionInput = {
  isSendToParent: boolean,
  sendToNonParentEmail?: string | null,
  sendToNonParentPhoneNumber?: string | null,
};

export type UpdateClassSessionDocumentsResult = {
  __typename: "UpdateClassSessionDocumentsResult",
  actionableErrorMsg?: string | null,
  classObject?: ClassObject | null,
};

export type UpdateClassSessionSummaryResult = {
  __typename: "UpdateClassSessionSummaryResult",
  actionableErrorMsg?: string | null,
  classObject?: ClassObject | null,
};

export type CreateClassStudentRecordInput = {
  username: string,
  classID: string,
  organizationID: string,
  sessionRecords: Array< SessionRecordForStudentInput >,
  sentClassLevelCommunications: Array< StudentCommunicationType >,
  endOfClassEvaluations: Array< EvaluationItemWithResultsInput >,
};

export type SessionRecordForStudentInput = {
  isAssigned: boolean,
  attended?: boolean | null,
  notAttendedReason?: string | null,
  documents: Array< S3FileInput >,
  sessionEvaluations: Array< EvaluationItemWithResultsInput >,
  sentCommunications: Array< StudentCommunicationType >,
};

export type EvaluationItemWithResultsInput = {
  metric: EvaluationMetric,
  results: Array< EvaluationMetricFormatResultInput >,
};

export type EvaluationMetricFormatResultInput = {
  format: EvaluationMetricFormat,
  required: boolean,
  result?: string | null,
};

export type UpdateClassStudentRecordInput = {
  id: string,
  sessionRecords: Array< SessionRecordForStudentInput >,
  endOfClassEvaluations: Array< EvaluationItemWithResultsInput >,
  documents: Array< S3FileInput >,
  sentClassLevelCommunications: Array< StudentCommunicationType >,
  currentVersionUpdatedAt: string,
};

export type UpdateClassStudentRecordsOutput = {
  __typename: "UpdateClassStudentRecordsOutput",
  errorReasonForUser?: string | null,
  results:  Array<ClassStudentRecord >,
};

export type RemoveClassStudentRecordInput = {
  id: string,
};

export type CreateClassTeacherRecordInput = {
  username: string,
  classID: string,
  organizationID: string,
  sessionRecords: Array< SessionRecordForTeacherInput | null >,
};

export type SessionRecordForTeacherInput = {
  role: TeacherRole,
  documents: Array< S3FileInput >,
};

export enum TeacherRole {
  MAIN_TEACHER = "MAIN_TEACHER",
  ASSISTANT_TEACHER = "ASSISTANT_TEACHER",
}


export type ClassTeacherRecord = {
  __typename: "ClassTeacherRecord",
  id: string,
  organizationID: string,
  username: string,
  classID: string,
  sessionRecords:  Array<SessionRecordForTeacher | null >,
  documents:  Array<S3File >,
  createdAt: string,
  updatedAt: string,
};

export type SessionRecordForTeacher = {
  __typename: "SessionRecordForTeacher",
  role: TeacherRole,
  documents:  Array<S3File >,
  updatedAt: string,
  updatedByUsername: string,
};

export type UpdateClassTeacherRecordInput = {
  id: string,
  sessionRecords: Array< SessionRecordForTeacherInput | null >,
  documents: Array< S3FileInput >,
  currentVersionUpdatedAt: string,
};

export type UpdateClassTeacherRecordsOutput = {
  __typename: "UpdateClassTeacherRecordsOutput",
  errorReasonForUser?: string | null,
  results:  Array<ClassTeacherRecord >,
};

export type RemoveClassTeacherRecordInput = {
  id: string,
};

export type ModelGlobalConfigurationsFilterInput = {
  id?: ModelIDInput | null,
  latestAppVersion?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGlobalConfigurationsFilterInput | null > | null,
  or?: Array< ModelGlobalConfigurationsFilterInput | null > | null,
  not?: ModelGlobalConfigurationsFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGlobalConfigurationsConnection = {
  __typename: "ModelGlobalConfigurationsConnection",
  items:  Array<GlobalConfigurations | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelRobotActionFilterInput = {
  organizationID?: ModelIDInput | null,
  actionID?: ModelIDInput | null,
  actionName?: ModelStringInput | null,
  actionNameEmbedding?: ModelFloatInput | null,
  embeddingModel?: ModelEmbeddingModelInput | null,
  textToSayEn?: ModelStringInput | null,
  textToSayZh?: ModelStringInput | null,
  actionBlocklyProjectId?: ModelIDInput | null,
  actionBlocklyProjectVersion?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRobotActionFilterInput | null > | null,
  or?: Array< ModelRobotActionFilterInput | null > | null,
  not?: ModelRobotActionFilterInput | null,
};

export type ModelRobotActionConnection = {
  __typename: "ModelRobotActionConnection",
  items:  Array<RobotAction | null >,
  nextToken?: string | null,
};

export type ModelAudioRecordingFilterInput = {
  s3bucketKey?: ModelIDInput | null,
  whisperResult?: ModelStringInput | null,
  googleSpeechToText?: ModelStringInput | null,
  awsTranscribeResult?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAudioRecordingFilterInput | null > | null,
  or?: Array< ModelAudioRecordingFilterInput | null > | null,
  not?: ModelAudioRecordingFilterInput | null,
};

export type ModelAudioRecordingConnection = {
  __typename: "ModelAudioRecordingConnection",
  items:  Array<AudioRecording | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelConversationHistoryFilterInput = {
  deviceID?: ModelIDInput | null,
  startDateTime?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationHistoryFilterInput | null > | null,
  or?: Array< ModelConversationHistoryFilterInput | null > | null,
  not?: ModelConversationHistoryFilterInput | null,
};

export type ModelConversationHistoryConnection = {
  __typename: "ModelConversationHistoryConnection",
  items:  Array<ConversationHistory | null >,
  nextToken?: string | null,
};

export type ModelQuestionAndAnswerRecordsV2FilterInput = {
  deviceID?: ModelIDInput | null,
  dateTime?: ModelStringInput | null,
  source?: ModelStringInput | null,
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelQuestionAndAnswerRecordsV2FilterInput | null > | null,
  or?: Array< ModelQuestionAndAnswerRecordsV2FilterInput | null > | null,
  not?: ModelQuestionAndAnswerRecordsV2FilterInput | null,
};

export type ModelQuestionAndAnswerRecordsV2Connection = {
  __typename: "ModelQuestionAndAnswerRecordsV2Connection",
  items:  Array<QuestionAndAnswerRecordsV2 | null >,
  nextToken?: string | null,
};

export type ModelAppLoggingFilterInput = {
  userID?: ModelIDInput | null,
  dateTime?: ModelStringInput | null,
  namespace?: ModelStringInput | null,
  message?: ModelStringInput | null,
  stacktrace?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAppLoggingFilterInput | null > | null,
  or?: Array< ModelAppLoggingFilterInput | null > | null,
  not?: ModelAppLoggingFilterInput | null,
};

export type ModelAppLoggingConnection = {
  __typename: "ModelAppLoggingConnection",
  items:  Array<AppLogging | null >,
  nextToken?: string | null,
};

export type ModelOrganizationDataFilterInput = {
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  compreFaceFaceRecognitionApiKey?: ModelStringInput | null,
  usePaidSpeechToText?: ModelBooleanInput | null,
  usePaidTextToSpeech?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isSchoolManagementEnabled?: ModelBooleanInput | null,
  isKebbiForBusinessEnabled?: ModelBooleanInput | null,
  allowedOrgRoles?: ModelOrgRoleListInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelOrganizationDataFilterInput | null > | null,
  or?: Array< ModelOrganizationDataFilterInput | null > | null,
  not?: ModelOrganizationDataFilterInput | null,
};

export type ModelOrganizationDataConnection = {
  __typename: "ModelOrganizationDataConnection",
  items:  Array<OrganizationData | null >,
  nextToken?: string | null,
};

export type ModelFaceRecognitionMomentsForImprovementsLogFilterInput = {
  id?: ModelIDInput | null,
  kebbiDeviceId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  outcome?: ModelFaceRecognitionLogOutcomeInput | null,
  logReason?: ModelFaceRecognitionLogReasonInput | null,
  recognizedUser?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFaceRecognitionMomentsForImprovementsLogFilterInput | null > | null,
  or?: Array< ModelFaceRecognitionMomentsForImprovementsLogFilterInput | null > | null,
  not?: ModelFaceRecognitionMomentsForImprovementsLogFilterInput | null,
};

export type ModelFaceRecognitionMomentsForImprovementsLogConnection = {
  __typename: "ModelFaceRecognitionMomentsForImprovementsLogConnection",
  items:  Array<FaceRecognitionMomentsForImprovementsLog | null >,
  nextToken?: string | null,
};

export type ModelKebbiDeviceFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelStringInput | null,
  cognitoIdentityID?: ModelStringInput | null,
  cognitoUserID?: ModelStringInput | null,
  password?: ModelStringInput | null,
  version?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isTestDevice?: ModelBooleanInput | null,
  and?: Array< ModelKebbiDeviceFilterInput | null > | null,
  or?: Array< ModelKebbiDeviceFilterInput | null > | null,
  not?: ModelKebbiDeviceFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelKebbiDeviceConnection = {
  __typename: "ModelKebbiDeviceConnection",
  items:  Array<KebbiDevice | null >,
  nextToken?: string | null,
};

export type ModelOrganizationUserFilterInput = {
  organizationID?: ModelIDInput | null,
  username?: ModelStringInput | null,
  status?: ModelOrganizationUserStatusInput | null,
  role?: ModelOrgRoleInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isShadowAdmin?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelOrganizationUserFilterInput | null > | null,
  or?: Array< ModelOrganizationUserFilterInput | null > | null,
  not?: ModelOrganizationUserFilterInput | null,
};

export type ModelOrganizationUserConnection = {
  __typename: "ModelOrganizationUserConnection",
  items:  Array<OrganizationUser | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  userName?: ModelIDInput | null,
  cognitoIdentityID?: ModelStringInput | null,
  password?: ModelStringInput | null,
  organizations?: ModelIDInput | null,
  parentName?: ModelStringInput | null,
  legalFullName?: ModelStringInput | null,
  preferredName?: ModelStringInput | null,
  country?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  about?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelTempAuthTokenFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  expirationAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTempAuthTokenFilterInput | null > | null,
  or?: Array< ModelTempAuthTokenFilterInput | null > | null,
  not?: ModelTempAuthTokenFilterInput | null,
};

export type ModelTempAuthTokenConnection = {
  __typename: "ModelTempAuthTokenConnection",
  items:  Array<TempAuthToken | null >,
  nextToken?: string | null,
};

export type ModelActivationCodeFilterInput = {
  id?: ModelIDInput | null,
  durationInDays?: ModelIntInput | null,
  serviceNames?: ModelServiceNameInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelActivationCodeFilterInput | null > | null,
  or?: Array< ModelActivationCodeFilterInput | null > | null,
  not?: ModelActivationCodeFilterInput | null,
};

export type ModelActivationCodeConnection = {
  __typename: "ModelActivationCodeConnection",
  items:  Array<ActivationCode | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelKebbiMaterialsFilterInput = {
  materialType?: ModelKebbiMaterialTypeInput | null,
  page?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelKebbiMaterialsFilterInput | null > | null,
  or?: Array< ModelKebbiMaterialsFilterInput | null > | null,
  not?: ModelKebbiMaterialsFilterInput | null,
};

export type ModelKebbiMaterialTypeInput = {
  eq?: KebbiMaterialType | null,
  ne?: KebbiMaterialType | null,
};

export type ModelKebbiMaterialsConnection = {
  __typename: "ModelKebbiMaterialsConnection",
  items:  Array<KebbiMaterials | null >,
  nextToken?: string | null,
};

export type ModelBlocklyProjectFilterInput = {
  id?: ModelIDInput | null,
  groupID?: ModelStringInput | null,
  workspaceJson?: ModelStringInput | null,
  projectName?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  machineLearningProjectIDs?: ModelIDInput | null,
  and?: Array< ModelBlocklyProjectFilterInput | null > | null,
  or?: Array< ModelBlocklyProjectFilterInput | null > | null,
  not?: ModelBlocklyProjectFilterInput | null,
};

export type ModelBlocklyProjectConnection = {
  __typename: "ModelBlocklyProjectConnection",
  items:  Array<BlocklyProject | null >,
  nextToken?: string | null,
};

export type ModelUserSubscriptionEventStatusFilterInput = {
  userID?: ModelIDInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserSubscriptionEventStatusFilterInput | null > | null,
  or?: Array< ModelUserSubscriptionEventStatusFilterInput | null > | null,
  not?: ModelUserSubscriptionEventStatusFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserSubscriptionEventStatusConnection = {
  __typename: "ModelUserSubscriptionEventStatusConnection",
  items:  Array<UserSubscriptionEventStatus | null >,
  nextToken?: string | null,
};

export type UserWhatsAppMessage = {
  __typename: "UserWhatsAppMessage",
  messageID: string,
  customerNumber: string,
  businessPhoneNumber: string,
  isFromBizToCustomer: boolean,
  timestampInSecond: number,
  status: MessageSendStatus,
  textMessage?: string | null,
  imageS3Key?: S3File | null,
  videoS3Key?: S3File | null,
  documentS3Key?: S3File | null,
  reactionEmoji?: string | null,
  quotedMessageId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum MessageSendStatus {
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
  READ = "READ",
  SENT = "SENT",
}


export type ModelUserWhatsAppMessageFilterInput = {
  messageID?: ModelIDInput | null,
  customerNumber?: ModelStringInput | null,
  businessPhoneNumber?: ModelStringInput | null,
  isFromBizToCustomer?: ModelBooleanInput | null,
  timestampInSecond?: ModelIntInput | null,
  status?: ModelMessageSendStatusInput | null,
  textMessage?: ModelStringInput | null,
  reactionEmoji?: ModelStringInput | null,
  quotedMessageId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelUserWhatsAppMessageFilterInput | null > | null,
  or?: Array< ModelUserWhatsAppMessageFilterInput | null > | null,
  not?: ModelUserWhatsAppMessageFilterInput | null,
};

export type ModelMessageSendStatusInput = {
  eq?: MessageSendStatus | null,
  ne?: MessageSendStatus | null,
};

export type ModelUserWhatsAppMessageConnection = {
  __typename: "ModelUserWhatsAppMessageConnection",
  items:  Array<UserWhatsAppMessage | null >,
  nextToken?: string | null,
};

export type ListBlocklyProjectsOutput = {
  __typename: "ListBlocklyProjectsOutput",
  items:  Array<BlocklyProject >,
  nextToken?: string | null,
};

export type ListConversationHistoryOutput = {
  __typename: "ListConversationHistoryOutput",
  items:  Array<ConversationHistory >,
  nextToken?: string | null,
};

export type ListOrganizationUsersByOrganizationIDOutput = {
  __typename: "ListOrganizationUsersByOrganizationIDOutput",
  items:  Array<UserWithOrgData >,
  nextToken?: string | null,
};

export type ListKebbiDevicesByOrganizationIDOutput = {
  __typename: "ListKebbiDevicesByOrganizationIDOutput",
  items:  Array<KebbiDeviceForOrganization >,
  nextToken?: string | null,
};

export type KebbiDeviceForOrganization = {
  __typename: "KebbiDeviceForOrganization",
  id: string,
  organizationID: string,
  cognitoIdentityID: string,
  cognitoUserID: string,
  version?: string | null,
  createdAt: string,
  updatedAt: string,
  isTestDevice?: boolean | null,
};

export type ListRobotFAQsOutput = {
  __typename: "ListRobotFAQsOutput",
  robotFAQs:  Array<RobotFAQ >,
  nextToken?: string | null,
};

export type ListMachineLearningProjectsOutput = {
  __typename: "ListMachineLearningProjectsOutput",
  items:  Array<MachineLearningProject >,
  nextToken?: string | null,
};

export type ListCodespaceProjectsOutput = {
  __typename: "ListCodespaceProjectsOutput",
  items:  Array<CodespaceProject >,
  nextToken?: string | null,
};

export type FileDownloadLink = {
  __typename: "FileDownloadLink",
  s3Key: string,
  s3PreSignedUrl: string,
};

export type FileUploadLink = {
  __typename: "FileUploadLink",
  s3Key: string,
  s3PreSignedUrl: string,
  documentType: DocumentType,
};

export type S3FileUploadInput = {
  ownerID: string,
  ownerType: OwnerType,
  fileName: string,
  contentType: string,
  documentType: DocumentType,
};

export type ListCentresOutput = {
  __typename: "ListCentresOutput",
  items:  Array<Centre >,
  nextToken?: string | null,
};

export type ListClassroomOutput = {
  __typename: "ListClassroomOutput",
  items:  Array<Classroom >,
  nextToken?: string | null,
};

export type ListCurriculumOutput = {
  __typename: "ListCurriculumOutput",
  items:  Array<Curriculum >,
  nextToken?: string | null,
};

export type ListClassObjectsWithAttendeeDetailsByUsernameOutput = {
  __typename: "ListClassObjectsWithAttendeeDetailsByUsernameOutput",
  items:  Array<ClassObjectWithAttendeeDetails >,
  nextToken?: string | null,
};

export type ClassObjectWithAttendeeDetails = {
  __typename: "ClassObjectWithAttendeeDetails",
  classObject: ClassObject,
  students:  Array<ClassStudentRecordWithStudentDetails >,
  teachers:  Array<ClassTeacherRecordWithTeacherDetails >,
};

export type ClassStudentRecordWithStudentDetails = {
  __typename: "ClassStudentRecordWithStudentDetails",
  record: ClassStudentRecord,
  userDetails: User,
};

export type ClassTeacherRecordWithTeacherDetails = {
  __typename: "ClassTeacherRecordWithTeacherDetails",
  record: ClassTeacherRecord,
  userDetails: User,
};

export type GetCredentialsFromAuthTokenResponse = {
  __typename: "GetCredentialsFromAuthTokenResponse",
  errorMessage?: string | null,
  username?: string | null,
  pwd?: string | null,
};

export type ModelSubscriptionGlobalConfigurationsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  latestAppVersion?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGlobalConfigurationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionGlobalConfigurationsFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionRobotActionFilterInput = {
  organizationID?: ModelSubscriptionIDInput | null,
  actionID?: ModelSubscriptionIDInput | null,
  actionName?: ModelSubscriptionStringInput | null,
  actionNameEmbedding?: ModelSubscriptionFloatInput | null,
  embeddingModel?: ModelSubscriptionStringInput | null,
  textToSayEn?: ModelSubscriptionStringInput | null,
  textToSayZh?: ModelSubscriptionStringInput | null,
  actionBlocklyProjectId?: ModelSubscriptionIDInput | null,
  actionBlocklyProjectVersion?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRobotActionFilterInput | null > | null,
  or?: Array< ModelSubscriptionRobotActionFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAudioRecordingFilterInput = {
  s3bucketKey?: ModelSubscriptionIDInput | null,
  whisperResult?: ModelSubscriptionStringInput | null,
  googleSpeechToText?: ModelSubscriptionStringInput | null,
  awsTranscribeResult?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAudioRecordingFilterInput | null > | null,
  or?: Array< ModelSubscriptionAudioRecordingFilterInput | null > | null,
};

export type ModelSubscriptionConversationHistoryFilterInput = {
  deviceID?: ModelSubscriptionIDInput | null,
  startDateTime?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationHistoryFilterInput | null > | null,
};

export type ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput = {
  deviceID?: ModelSubscriptionIDInput | null,
  dateTime?: ModelSubscriptionStringInput | null,
  source?: ModelSubscriptionStringInput | null,
  question?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput | null > | null,
};

export type ModelSubscriptionAppLoggingFilterInput = {
  userID?: ModelSubscriptionIDInput | null,
  dateTime?: ModelSubscriptionStringInput | null,
  namespace?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  stacktrace?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAppLoggingFilterInput | null > | null,
  or?: Array< ModelSubscriptionAppLoggingFilterInput | null > | null,
};

export type ModelSubscriptionOrganizationDataFilterInput = {
  organizationID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  compreFaceFaceRecognitionApiKey?: ModelSubscriptionStringInput | null,
  usePaidSpeechToText?: ModelSubscriptionBooleanInput | null,
  usePaidTextToSpeech?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  isSchoolManagementEnabled?: ModelSubscriptionBooleanInput | null,
  isKebbiForBusinessEnabled?: ModelSubscriptionBooleanInput | null,
  allowedOrgRoles?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOrganizationDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationDataFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  kebbiDeviceId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  outcome?: ModelSubscriptionStringInput | null,
  logReason?: ModelSubscriptionStringInput | null,
  recognizedUser?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput | null > | null,
};

export type ModelSubscriptionKebbiDeviceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionStringInput | null,
  cognitoIdentityID?: ModelSubscriptionStringInput | null,
  cognitoUserID?: ModelSubscriptionStringInput | null,
  password?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  isTestDevice?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionKebbiDeviceFilterInput | null > | null,
  or?: Array< ModelSubscriptionKebbiDeviceFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserSubscriptionEventStatusFilterInput = {
  userID?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserSubscriptionEventStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSubscriptionEventStatusFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type CreateGlobalConfigurationsMutationVariables = {
  input: CreateGlobalConfigurationsInput,
  condition?: ModelGlobalConfigurationsConditionInput | null,
};

export type CreateGlobalConfigurationsMutation = {
  createGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGlobalConfigurationsMutationVariables = {
  input: UpdateGlobalConfigurationsInput,
  condition?: ModelGlobalConfigurationsConditionInput | null,
};

export type UpdateGlobalConfigurationsMutation = {
  updateGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGlobalConfigurationsMutationVariables = {
  input: DeleteGlobalConfigurationsInput,
  condition?: ModelGlobalConfigurationsConditionInput | null,
};

export type DeleteGlobalConfigurationsMutation = {
  deleteGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRobotActionMutationVariables = {
  input: CreateRobotActionInput,
  condition?: ModelRobotActionConditionInput | null,
};

export type CreateRobotActionMutation = {
  createRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRobotActionMutationVariables = {
  input: UpdateRobotActionInput,
  condition?: ModelRobotActionConditionInput | null,
};

export type UpdateRobotActionMutation = {
  updateRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRobotActionMutationVariables = {
  input: DeleteRobotActionInput,
  condition?: ModelRobotActionConditionInput | null,
};

export type DeleteRobotActionMutation = {
  deleteRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAudioRecordingMutationVariables = {
  input: CreateAudioRecordingInput,
  condition?: ModelAudioRecordingConditionInput | null,
};

export type CreateAudioRecordingMutation = {
  createAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAudioRecordingMutationVariables = {
  input: UpdateAudioRecordingInput,
  condition?: ModelAudioRecordingConditionInput | null,
};

export type UpdateAudioRecordingMutation = {
  updateAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAudioRecordingMutationVariables = {
  input: DeleteAudioRecordingInput,
  condition?: ModelAudioRecordingConditionInput | null,
};

export type DeleteAudioRecordingMutation = {
  deleteAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConversationHistoryMutationVariables = {
  input: CreateConversationHistoryInput,
  condition?: ModelConversationHistoryConditionInput | null,
};

export type CreateConversationHistoryMutation = {
  createConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversationHistoryMutationVariables = {
  input: UpdateConversationHistoryInput,
  condition?: ModelConversationHistoryConditionInput | null,
};

export type UpdateConversationHistoryMutation = {
  updateConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversationHistoryMutationVariables = {
  input: DeleteConversationHistoryInput,
  condition?: ModelConversationHistoryConditionInput | null,
};

export type DeleteConversationHistoryMutation = {
  deleteConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionAndAnswerRecordsV2MutationVariables = {
  input: CreateQuestionAndAnswerRecordsV2Input,
  condition?: ModelQuestionAndAnswerRecordsV2ConditionInput | null,
};

export type CreateQuestionAndAnswerRecordsV2Mutation = {
  createQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionAndAnswerRecordsV2MutationVariables = {
  input: UpdateQuestionAndAnswerRecordsV2Input,
  condition?: ModelQuestionAndAnswerRecordsV2ConditionInput | null,
};

export type UpdateQuestionAndAnswerRecordsV2Mutation = {
  updateQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionAndAnswerRecordsV2MutationVariables = {
  input: DeleteQuestionAndAnswerRecordsV2Input,
  condition?: ModelQuestionAndAnswerRecordsV2ConditionInput | null,
};

export type DeleteQuestionAndAnswerRecordsV2Mutation = {
  deleteQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAppLoggingMutationVariables = {
  input: CreateAppLoggingInput,
  condition?: ModelAppLoggingConditionInput | null,
};

export type CreateAppLoggingMutation = {
  createAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppLoggingMutationVariables = {
  input: UpdateAppLoggingInput,
  condition?: ModelAppLoggingConditionInput | null,
};

export type UpdateAppLoggingMutation = {
  updateAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppLoggingMutationVariables = {
  input: DeleteAppLoggingInput,
  condition?: ModelAppLoggingConditionInput | null,
};

export type DeleteAppLoggingMutation = {
  deleteAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganizationDataMutationVariables = {
  input: CreateOrganizationDataInput,
  condition?: ModelOrganizationDataConditionInput | null,
};

export type CreateOrganizationDataMutation = {
  createOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type UpdateOrganizationDataMutationVariables = {
  input: UpdateOrganizationDataInput,
  condition?: ModelOrganizationDataConditionInput | null,
};

export type UpdateOrganizationDataMutation = {
  updateOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type DeleteOrganizationDataMutationVariables = {
  input: DeleteOrganizationDataInput,
  condition?: ModelOrganizationDataConditionInput | null,
};

export type DeleteOrganizationDataMutation = {
  deleteOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type CreateFaceRecognitionMomentsForImprovementsLogMutationVariables = {
  input: CreateFaceRecognitionMomentsForImprovementsLogInput,
  condition?: ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null,
};

export type CreateFaceRecognitionMomentsForImprovementsLogMutation = {
  createFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFaceRecognitionMomentsForImprovementsLogMutationVariables = {
  input: UpdateFaceRecognitionMomentsForImprovementsLogInput,
  condition?: ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null,
};

export type UpdateFaceRecognitionMomentsForImprovementsLogMutation = {
  updateFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFaceRecognitionMomentsForImprovementsLogMutationVariables = {
  input: DeleteFaceRecognitionMomentsForImprovementsLogInput,
  condition?: ModelFaceRecognitionMomentsForImprovementsLogConditionInput | null,
};

export type DeleteFaceRecognitionMomentsForImprovementsLogMutation = {
  deleteFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKebbiDeviceMutationVariables = {
  input: CreateKebbiDeviceInput,
  condition?: ModelKebbiDeviceConditionInput | null,
};

export type CreateKebbiDeviceMutation = {
  createKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type UpdateKebbiDeviceMutationVariables = {
  input: UpdateKebbiDeviceInput,
  condition?: ModelKebbiDeviceConditionInput | null,
};

export type UpdateKebbiDeviceMutation = {
  updateKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type DeleteKebbiDeviceMutationVariables = {
  input: DeleteKebbiDeviceInput,
  condition?: ModelKebbiDeviceConditionInput | null,
};

export type DeleteKebbiDeviceMutation = {
  deleteKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type CreateOrganizationUserMutationVariables = {
  input: CreateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type CreateOrganizationUserMutation = {
  createOrganizationUser?:  {
    __typename: "OrganizationUser",
    organizationID: string,
    username: string,
    status: OrganizationUserStatus,
    role: OrgRole,
    createdAt: string,
    updatedAt: string,
    isShadowAdmin?: boolean | null,
  } | null,
};

export type UpdateOrganizationUserMutationVariables = {
  input: UpdateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type UpdateOrganizationUserMutation = {
  updateOrganizationUser?:  {
    __typename: "OrganizationUser",
    organizationID: string,
    username: string,
    status: OrganizationUserStatus,
    role: OrgRole,
    createdAt: string,
    updatedAt: string,
    isShadowAdmin?: boolean | null,
  } | null,
};

export type DeleteOrganizationUserMutationVariables = {
  input: DeleteOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type DeleteOrganizationUserMutation = {
  deleteOrganizationUser?:  {
    __typename: "OrganizationUser",
    organizationID: string,
    username: string,
    status: OrganizationUserStatus,
    role: OrgRole,
    createdAt: string,
    updatedAt: string,
    isShadowAdmin?: boolean | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTempAuthTokenMutationVariables = {
  input: CreateTempAuthTokenInput,
  condition?: ModelTempAuthTokenConditionInput | null,
};

export type CreateTempAuthTokenMutation = {
  createTempAuthToken?:  {
    __typename: "TempAuthToken",
    id: string,
    username: string,
    expirationAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTempAuthTokenMutationVariables = {
  input: UpdateTempAuthTokenInput,
  condition?: ModelTempAuthTokenConditionInput | null,
};

export type UpdateTempAuthTokenMutation = {
  updateTempAuthToken?:  {
    __typename: "TempAuthToken",
    id: string,
    username: string,
    expirationAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTempAuthTokenMutationVariables = {
  input: DeleteTempAuthTokenInput,
  condition?: ModelTempAuthTokenConditionInput | null,
};

export type DeleteTempAuthTokenMutation = {
  deleteTempAuthToken?:  {
    __typename: "TempAuthToken",
    id: string,
    username: string,
    expirationAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateActivationCodeMutationVariables = {
  input: CreateActivationCodeInput,
  condition?: ModelActivationCodeConditionInput | null,
};

export type CreateActivationCodeMutation = {
  createActivationCode?:  {
    __typename: "ActivationCode",
    id: string,
    durationInDays: number,
    serviceNames: Array< ServiceName >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateActivationCodeMutationVariables = {
  input: UpdateActivationCodeInput,
  condition?: ModelActivationCodeConditionInput | null,
};

export type UpdateActivationCodeMutation = {
  updateActivationCode?:  {
    __typename: "ActivationCode",
    id: string,
    durationInDays: number,
    serviceNames: Array< ServiceName >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteActivationCodeMutationVariables = {
  input: DeleteActivationCodeInput,
  condition?: ModelActivationCodeConditionInput | null,
};

export type DeleteActivationCodeMutation = {
  deleteActivationCode?:  {
    __typename: "ActivationCode",
    id: string,
    durationInDays: number,
    serviceNames: Array< ServiceName >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKebbiMaterialsMutationVariables = {
  input: CreateKebbiMaterialsInput,
  condition?: ModelKebbiMaterialsConditionInput | null,
};

export type CreateKebbiMaterialsMutation = {
  createKebbiMaterials?:  {
    __typename: "KebbiMaterials",
    materialType: KebbiMaterialType,
    page: number,
    materials:  Array< {
      __typename: "KebbiMaterial",
      title: string,
      i18n:  {
        __typename: "i18n",
        en_US: string,
      },
      identityID: string,
      fileName: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKebbiMaterialsMutationVariables = {
  input: UpdateKebbiMaterialsInput,
  condition?: ModelKebbiMaterialsConditionInput | null,
};

export type UpdateKebbiMaterialsMutation = {
  updateKebbiMaterials?:  {
    __typename: "KebbiMaterials",
    materialType: KebbiMaterialType,
    page: number,
    materials:  Array< {
      __typename: "KebbiMaterial",
      title: string,
      i18n:  {
        __typename: "i18n",
        en_US: string,
      },
      identityID: string,
      fileName: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKebbiMaterialsMutationVariables = {
  input: DeleteKebbiMaterialsInput,
  condition?: ModelKebbiMaterialsConditionInput | null,
};

export type DeleteKebbiMaterialsMutation = {
  deleteKebbiMaterials?:  {
    __typename: "KebbiMaterials",
    materialType: KebbiMaterialType,
    page: number,
    materials:  Array< {
      __typename: "KebbiMaterial",
      title: string,
      i18n:  {
        __typename: "i18n",
        en_US: string,
      },
      identityID: string,
      fileName: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBlocklyProjectMutationVariables = {
  input: CreateBlocklyProjectInput,
  condition?: ModelBlocklyProjectConditionInput | null,
};

export type CreateBlocklyProjectMutation = {
  createBlocklyProject?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type UpdateBlocklyProjectMutationVariables = {
  input: UpdateBlocklyProjectInput,
  condition?: ModelBlocklyProjectConditionInput | null,
};

export type UpdateBlocklyProjectMutation = {
  updateBlocklyProject?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type DeleteBlocklyProjectMutationVariables = {
  input: DeleteBlocklyProjectInput,
  condition?: ModelBlocklyProjectConditionInput | null,
};

export type DeleteBlocklyProjectMutation = {
  deleteBlocklyProject?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type CreateUserSubscriptionEventStatusMutationVariables = {
  input: CreateUserSubscriptionEventStatusInput,
  condition?: ModelUserSubscriptionEventStatusConditionInput | null,
};

export type CreateUserSubscriptionEventStatusMutation = {
  createUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserSubscriptionEventStatusMutationVariables = {
  input: UpdateUserSubscriptionEventStatusInput,
  condition?: ModelUserSubscriptionEventStatusConditionInput | null,
};

export type UpdateUserSubscriptionEventStatusMutation = {
  updateUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserSubscriptionEventStatusMutationVariables = {
  input: DeleteUserSubscriptionEventStatusInput,
  condition?: ModelUserSubscriptionEventStatusConditionInput | null,
};

export type DeleteUserSubscriptionEventStatusMutation = {
  deleteUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBlocklyProjectCustomMutationVariables = {
  groupID?: string | null,
  workspaceJson: string,
  projectName: string,
  identityID: string,
  files: Array< FileTypeInput >,
};

export type CreateBlocklyProjectCustomMutation = {
  createBlocklyProjectCustom?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type UpdateUserOrganizationDetailsMutationVariables = {
  organizationID: string,
  username: string,
  role: OrgRole,
};

export type UpdateUserOrganizationDetailsMutation = {
  updateUserOrganizationDetails:  {
    __typename: "UpdateUserOrganizationDetailsResult",
    user:  {
      __typename: "User",
      userName: string,
      cognitoIdentityID?: string | null,
      password?: string | null,
      organizations?: Array< string > | null,
      profileImage?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      parentName?: string | null,
      legalFullName?: string | null,
      preferredName?: string | null,
      country?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      dateOfBirth?: string | null,
      about?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    organizationUser:  {
      __typename: "OrganizationUser",
      organizationID: string,
      username: string,
      status: OrganizationUserStatus,
      role: OrgRole,
      createdAt: string,
      updatedAt: string,
      isShadowAdmin?: boolean | null,
    },
  },
};

export type CreateUserCustomMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserCustomMutation = {
  createUserCustom:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateUserByOrganizationAdminMutationVariables = {
  userName: string,
  legalFullName: string,
  preferredName: string,
  country: string,
  email: string,
  parentName?: string | null,
  phoneNumber?: string | null,
  dateOfBirth?: string | null,
  about?: string | null,
  orgRole: OrgRole,
  organizationID: string,
};

export type CreateUserByOrganizationAdminMutation = {
  createUserByOrganizationAdmin:  {
    __typename: "CreateUserByOrganizationAdminResult",
    isUsernameAlreadyExistError: boolean,
    user?:  {
      __typename: "UserWithOrgData",
      user:  {
        __typename: "User",
        userName: string,
        cognitoIdentityID?: string | null,
        password?: string | null,
        organizations?: Array< string > | null,
        profileImage?:  {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } | null,
        parentName?: string | null,
        legalFullName?: string | null,
        preferredName?: string | null,
        country?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        dateOfBirth?: string | null,
        about?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      organizations:  Array< {
        __typename: "OrganizationDataForUser",
        userOrg:  {
          __typename: "OrganizationUser",
          organizationID: string,
          username: string,
          status: OrganizationUserStatus,
          role: OrgRole,
          createdAt: string,
          updatedAt: string,
          isShadowAdmin?: boolean | null,
        },
        orgName: string,
        userOrgPermission:  {
          __typename: "UserOrgPermission",
          canReadUsers: boolean,
          canCreateUser: boolean,
          canListCurriculums: boolean,
          canCreateCurriculum: boolean,
          canListClasses: boolean,
          canCreateClass: boolean,
          canListCentres: boolean,
          canCreateCentres: boolean,
          canListClassrooms: boolean,
          canCreateClassrooms: boolean,
          isKebbiForBusinessEnabled: boolean,
          isCodelabProEnabled: boolean,
        },
        allowedOrgRoles: Array< OrgRole >,
      } >,
    } | null,
  },
};

export type CreateStudentByFromInvitedRegistrationMutationVariables = {
  legalFullName: string,
  preferredName: string,
  email: string,
  parentName: string,
  phoneNumber: string,
  dateOfBirth: string,
  notes?: string | null,
};

export type CreateStudentByFromInvitedRegistrationMutation = {
  createStudentByFromInvitedRegistration: boolean,
};

export type UpdateUserInfoMutationVariables = {
  userName: string,
  legalFullName: string,
  preferredName: string,
  country: string,
  email: string,
  parentName?: string | null,
  phoneNumber?: string | null,
  dateOfBirth?: string | null,
  about?: string | null,
};

export type UpdateUserInfoMutation = {
  updateUserInfo:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type RecordUserPwdMutationVariables = {
  userName: string,
  pwd: string,
};

export type RecordUserPwdMutation = {
  recordUserPwd:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateUserPasswordMutationVariables = {
  userName: string,
  oldPassword: string,
  newPassword: string,
};

export type UpdateUserPasswordMutation = {
  updateUserPassword:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateUserProfileImageMutationVariables = {
  userName: string,
  profileImage: S3FileInput,
};

export type UpdateUserProfileImageMutation = {
  updateUserProfileImage:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateUserCognitoIdentityIdMutationVariables = {
  username: string,
  cognitoIdentityId: string,
};

export type UpdateUserCognitoIdentityIdMutation = {
  updateUserCognitoIdentityId:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateRobotFAQsMutationVariables = {
  inputs: CreateRobotFAQInputs,
};

export type CreateRobotFAQsMutation = {
  createRobotFAQs:  Array< {
    __typename: "RobotFAQ",
    organizationID: string,
    question: string,
    questionEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn: string,
    textToSayZh: string,
    createdAt: string,
    updatedAt: string,
  } >,
};

export type ConvertTfjsToTfLiteMutationVariables = {
  mlProjectID: string,
};

export type ConvertTfjsToTfLiteMutation = {
  convertTfjsToTfLite: boolean,
};

export type CreateMachineLearningProjectMutationVariables = {
  ownerID: string,
  ownerType: OwnerType,
  projectName: string,
  modelType: MachineLearningModelType,
};

export type CreateMachineLearningProjectMutation = {
  createMachineLearningProject?:  {
    __typename: "MachineLearningProject",
    id: string,
    ownerID: string,
    ownerType: OwnerType,
    projectName: string,
    modelType: MachineLearningModelType,
    modelS3RootDirectory: string,
    classes:  Array< {
      __typename: "MachineLearningClass",
      id: string,
      name: string,
      samplesZipS3File:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      },
      isDisabled: boolean,
    } >,
    targetLatestModelVersion?: number | null,
    tfjsModelVersion?: number | null,
    tfliteModelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    blocklyProjectIDs?: Array< string > | null,
  } | null,
};

export type UpdateMachineLearningProjectMutationVariables = {
  id: string,
  updatedProjectName: string,
  updatedClasses: Array< MachineLearningClassUpdate >,
  shouldGenerateFileUploadURL: boolean,
};

export type UpdateMachineLearningProjectMutation = {
  updateMachineLearningProject:  {
    __typename: "UpdateMachineLearningProjectOutput",
    updatedProject:  {
      __typename: "MachineLearningProject",
      id: string,
      ownerID: string,
      ownerType: OwnerType,
      projectName: string,
      modelType: MachineLearningModelType,
      modelS3RootDirectory: string,
      classes:  Array< {
        __typename: "MachineLearningClass",
        id: string,
        name: string,
        samplesZipS3File:  {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        },
        isDisabled: boolean,
      } >,
      targetLatestModelVersion?: number | null,
      tfjsModelVersion?: number | null,
      tfliteModelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      blocklyProjectIDs?: Array< string > | null,
    },
    getUploadURLsForMLTrainingOutput?:  {
      __typename: "GetUploadURLsForMLTrainingOutput",
      modelJsonUrl: string,
      modelWeightsUrl: string,
      modelLabelUrl: string,
      classSamplesUrls: Array< string >,
    } | null,
  },
};

export type StartTfjsModelTrainingMutationVariables = {
  mlProjectID: string,
};

export type StartTfjsModelTrainingMutation = {
  startTfjsModelTraining:  {
    __typename: "MachineLearningProject",
    id: string,
    ownerID: string,
    ownerType: OwnerType,
    projectName: string,
    modelType: MachineLearningModelType,
    modelS3RootDirectory: string,
    classes:  Array< {
      __typename: "MachineLearningClass",
      id: string,
      name: string,
      samplesZipS3File:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      },
      isDisabled: boolean,
    } >,
    targetLatestModelVersion?: number | null,
    tfjsModelVersion?: number | null,
    tfliteModelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    blocklyProjectIDs?: Array< string > | null,
  },
};

export type DeleteMachineLearningProjectMutationVariables = {
  id: string,
};

export type DeleteMachineLearningProjectMutation = {
  deleteMachineLearningProject: boolean,
};

export type CreateCodespaceProjectMutationVariables = {
  ownerID: string,
  ownerType: OwnerType,
  projectName: string,
  language: Languages,
};

export type CreateCodespaceProjectMutation = {
  createCodespaceProject?:  {
    __typename: "CodespaceProject",
    id: string,
    ownerID: string,
    ownerType: OwnerType,
    projectName: string,
    language: Languages,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCodespaceProjectMutationVariables = {
  id: string,
  updatedProjectName: string,
  code: string,
};

export type UpdateCodespaceProjectMutation = {
  updateCodespaceProject:  {
    __typename: "UpdateCodespaceProjectOutput",
    updatedProject:  {
      __typename: "CodespaceProject",
      id: string,
      ownerID: string,
      ownerType: OwnerType,
      projectName: string,
      language: Languages,
      code: string,
      createdAt: string,
      updatedAt: string,
    },
  },
};

export type DeleteCodespaceProjectMutationVariables = {
  id: string,
};

export type DeleteCodespaceProjectMutation = {
  deleteCodespaceProject: boolean,
};

export type CreateCentreMutationVariables = {
  organizationID: string,
  data: CentreDataInput,
  status: ItemStatus,
};

export type CreateCentreMutation = {
  createCentre:  {
    __typename: "Centre",
    id: string,
    organizationID: string,
    data:  {
      __typename: "CentreData",
      name: string,
      address: string,
      postcode: string,
      country: string,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateCentreMutationVariables = {
  id: string,
  data: CentreDataInput,
  status: ItemStatus,
};

export type UpdateCentreMutation = {
  updateCentre:  {
    __typename: "Centre",
    id: string,
    organizationID: string,
    data:  {
      __typename: "CentreData",
      name: string,
      address: string,
      postcode: string,
      country: string,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateClassroomMutationVariables = {
  organizationID: string,
  data: ClassroomDataInput,
  status: ItemStatus,
};

export type CreateClassroomMutation = {
  createClassroom:  {
    __typename: "Classroom",
    id: string,
    organizationID: string,
    data:  {
      __typename: "ClassroomData",
      centreId: string,
      centre:  {
        __typename: "CentreData",
        name: string,
        address: string,
        postcode: string,
        country: string,
      },
      name: string,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateClassroomMutationVariables = {
  id: string,
  data: ClassroomDataInput,
  status: ItemStatus,
};

export type UpdateClassroomMutation = {
  updateClassroom:  {
    __typename: "Classroom",
    id: string,
    organizationID: string,
    data:  {
      __typename: "ClassroomData",
      centreId: string,
      centre:  {
        __typename: "CentreData",
        name: string,
        address: string,
        postcode: string,
        country: string,
      },
      name: string,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateCurriculumMutationVariables = {
  organizationID: string,
  data: CurriculumDataInput,
  status: ItemStatus,
};

export type CreateCurriculumMutation = {
  createCurriculum:  {
    __typename: "Curriculum",
    id: string,
    organizationID: string,
    data:  {
      __typename: "CurriculumData",
      name: string,
      description: string,
      covers:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      type: CurriculumType,
      sessionTemplates:  Array< {
        __typename: "CurriculumSessionTemplate",
        sessionName: string,
        sessionDescription: string,
        sessionDurationInMinutes: number,
        evaluationItems:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requireSessionPhotoOrVideoFromTeacher: boolean,
        requireSessionSummaryFromTeacher: boolean,
        requireEachStudentPhotoOrVideoFromTeacher: boolean,
        requiredCommunications: Array< StudentCommunicationType >,
      } >,
      endOfClassEvaluationsTemplate:  Array< {
        __typename: "EvaluationItem",
        metric: EvaluationMetric,
        formats:  Array< {
          __typename: "EvaluationMetricFormatValue",
          format: EvaluationMetricFormat,
          required: boolean,
        } >,
      } >,
      requiredClassLevelCommunications: Array< StudentCommunicationType >,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateCurriculumStatusMutationVariables = {
  id: string,
  status: ItemStatus,
};

export type UpdateCurriculumStatusMutation = {
  updateCurriculumStatus:  {
    __typename: "Curriculum",
    id: string,
    organizationID: string,
    data:  {
      __typename: "CurriculumData",
      name: string,
      description: string,
      covers:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      type: CurriculumType,
      sessionTemplates:  Array< {
        __typename: "CurriculumSessionTemplate",
        sessionName: string,
        sessionDescription: string,
        sessionDurationInMinutes: number,
        evaluationItems:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requireSessionPhotoOrVideoFromTeacher: boolean,
        requireSessionSummaryFromTeacher: boolean,
        requireEachStudentPhotoOrVideoFromTeacher: boolean,
        requiredCommunications: Array< StudentCommunicationType >,
      } >,
      endOfClassEvaluationsTemplate:  Array< {
        __typename: "EvaluationItem",
        metric: EvaluationMetric,
        formats:  Array< {
          __typename: "EvaluationMetricFormatValue",
          format: EvaluationMetricFormat,
          required: boolean,
        } >,
      } >,
      requiredClassLevelCommunications: Array< StudentCommunicationType >,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateCurriculumDataMutationVariables = {
  id: string,
  affectedClassIds: Array< string >,
  curriculumData: CurriculumDataInput,
  currentVersionUpdatedAt: string,
};

export type UpdateCurriculumDataMutation = {
  updateCurriculumData:  {
    __typename: "UpdateCurriculumDataOutput",
    actionableErrorMsg?: string | null,
    updatedCurriculum?:  {
      __typename: "Curriculum",
      id: string,
      organizationID: string,
      data:  {
        __typename: "CurriculumData",
        name: string,
        description: string,
        covers:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        type: CurriculumType,
        sessionTemplates:  Array< {
          __typename: "CurriculumSessionTemplate",
          sessionName: string,
          sessionDescription: string,
          sessionDurationInMinutes: number,
          evaluationItems:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requireSessionPhotoOrVideoFromTeacher: boolean,
          requireSessionSummaryFromTeacher: boolean,
          requireEachStudentPhotoOrVideoFromTeacher: boolean,
          requiredCommunications: Array< StudentCommunicationType >,
        } >,
        endOfClassEvaluationsTemplate:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requiredClassLevelCommunications: Array< StudentCommunicationType >,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedClasses?:  Array< {
      __typename: "UpdatedClassAndStudentRecords",
      updatedClass:  {
        __typename: "ClassObject",
        id: string,
        organizationID: string,
        curriculumId: string,
        curriculum:  {
          __typename: "CurriculumData",
          name: string,
          description: string,
          covers:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          type: CurriculumType,
          sessionTemplates:  Array< {
            __typename: "CurriculumSessionTemplate",
            sessionName: string,
            sessionDescription: string,
            sessionDurationInMinutes: number,
            evaluationItems:  Array< {
              __typename: "EvaluationItem",
              metric: EvaluationMetric,
              formats:  Array< {
                __typename: "EvaluationMetricFormatValue",
                format: EvaluationMetricFormat,
                required: boolean,
              } >,
            } >,
            requireSessionPhotoOrVideoFromTeacher: boolean,
            requireSessionSummaryFromTeacher: boolean,
            requireEachStudentPhotoOrVideoFromTeacher: boolean,
            requiredCommunications: Array< StudentCommunicationType >,
          } >,
          endOfClassEvaluationsTemplate:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requiredClassLevelCommunications: Array< StudentCommunicationType >,
        },
        sessions:  Array< {
          __typename: "Session",
          sessionLevelSharedFiles:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          sessionSummary?: string | null,
          startDateTime: string,
          classroom:  {
            __typename: "ClassroomData",
            centreId: string,
            centre:  {
              __typename: "CentreData",
              name: string,
              address: string,
              postcode: string,
              country: string,
            },
            name: string,
          },
        } >,
        classLevelSharedFiles:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        classCommunicationOption:  {
          __typename: "ClassCommunicationOption",
          isSendToParent: boolean,
          sendToNonParentEmail?: string | null,
          sendToNonParentPhoneNumber?: string | null,
        },
        status: ItemStatus,
        createdAt: string,
        updatedAt: string,
      },
      updatedClassStudentRecords:  Array< {
        __typename: "ClassStudentRecord",
        id: string,
        organizationID: string,
        username: string,
        classID: string,
        sessionRecords:  Array< {
          __typename: "SessionRecordForStudent",
          isAssigned: boolean,
          attended?: boolean | null,
          notAttendedReason?: string | null,
          documents:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          sessionEvaluations:  Array< {
            __typename: "EvaluationItemWithResults",
            metric: EvaluationMetric,
            results:  Array< {
              __typename: "EvaluationMetricFormatResult",
              format: EvaluationMetricFormat,
              required: boolean,
              result?: string | null,
            } >,
          } >,
          sentCommunications: Array< StudentCommunicationType >,
          updatedAt: string,
          updatedByUsername: string,
        } >,
        endOfClassEvaluations:  Array< {
          __typename: "EvaluationItemWithResults",
          metric: EvaluationMetric,
          results:  Array< {
            __typename: "EvaluationMetricFormatResult",
            format: EvaluationMetricFormat,
            required: boolean,
            result?: string | null,
          } >,
        } >,
        sentClassLevelCommunications: Array< StudentCommunicationType >,
        documents:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        createdAt: string,
        updatedAt: string,
      } >,
    } > | null,
  },
};

export type CreateClassForCurriculumMutationVariables = {
  createClassInput: CreateClassInput,
};

export type CreateClassForCurriculumMutation = {
  createClassForCurriculum:  {
    __typename: "ClassObject",
    id: string,
    organizationID: string,
    curriculumId: string,
    curriculum:  {
      __typename: "CurriculumData",
      name: string,
      description: string,
      covers:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      type: CurriculumType,
      sessionTemplates:  Array< {
        __typename: "CurriculumSessionTemplate",
        sessionName: string,
        sessionDescription: string,
        sessionDurationInMinutes: number,
        evaluationItems:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requireSessionPhotoOrVideoFromTeacher: boolean,
        requireSessionSummaryFromTeacher: boolean,
        requireEachStudentPhotoOrVideoFromTeacher: boolean,
        requiredCommunications: Array< StudentCommunicationType >,
      } >,
      endOfClassEvaluationsTemplate:  Array< {
        __typename: "EvaluationItem",
        metric: EvaluationMetric,
        formats:  Array< {
          __typename: "EvaluationMetricFormatValue",
          format: EvaluationMetricFormat,
          required: boolean,
        } >,
      } >,
      requiredClassLevelCommunications: Array< StudentCommunicationType >,
    },
    sessions:  Array< {
      __typename: "Session",
      sessionLevelSharedFiles:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      sessionSummary?: string | null,
      startDateTime: string,
      classroom:  {
        __typename: "ClassroomData",
        centreId: string,
        centre:  {
          __typename: "CentreData",
          name: string,
          address: string,
          postcode: string,
          country: string,
        },
        name: string,
      },
    } >,
    classLevelSharedFiles:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } >,
    classCommunicationOption:  {
      __typename: "ClassCommunicationOption",
      isSendToParent: boolean,
      sendToNonParentEmail?: string | null,
      sendToNonParentPhoneNumber?: string | null,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type UpdateClassSessionDocumentsMutationVariables = {
  classID: string,
  sessionIndex: number,
  files: Array< S3FileInput >,
  currentVersionUpdatedAt: string,
};

export type UpdateClassSessionDocumentsMutation = {
  updateClassSessionDocuments:  {
    __typename: "UpdateClassSessionDocumentsResult",
    actionableErrorMsg?: string | null,
    classObject?:  {
      __typename: "ClassObject",
      id: string,
      organizationID: string,
      curriculumId: string,
      curriculum:  {
        __typename: "CurriculumData",
        name: string,
        description: string,
        covers:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        type: CurriculumType,
        sessionTemplates:  Array< {
          __typename: "CurriculumSessionTemplate",
          sessionName: string,
          sessionDescription: string,
          sessionDurationInMinutes: number,
          evaluationItems:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requireSessionPhotoOrVideoFromTeacher: boolean,
          requireSessionSummaryFromTeacher: boolean,
          requireEachStudentPhotoOrVideoFromTeacher: boolean,
          requiredCommunications: Array< StudentCommunicationType >,
        } >,
        endOfClassEvaluationsTemplate:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requiredClassLevelCommunications: Array< StudentCommunicationType >,
      },
      sessions:  Array< {
        __typename: "Session",
        sessionLevelSharedFiles:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        sessionSummary?: string | null,
        startDateTime: string,
        classroom:  {
          __typename: "ClassroomData",
          centreId: string,
          centre:  {
            __typename: "CentreData",
            name: string,
            address: string,
            postcode: string,
            country: string,
          },
          name: string,
        },
      } >,
      classLevelSharedFiles:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      classCommunicationOption:  {
        __typename: "ClassCommunicationOption",
        isSendToParent: boolean,
        sendToNonParentEmail?: string | null,
        sendToNonParentPhoneNumber?: string | null,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
  },
};

export type UpdateClassSessionSummaryMutationVariables = {
  classID: string,
  sessionIndex: number,
  sessionSummary: string,
  currentVersionUpdatedAt: string,
};

export type UpdateClassSessionSummaryMutation = {
  updateClassSessionSummary:  {
    __typename: "UpdateClassSessionSummaryResult",
    actionableErrorMsg?: string | null,
    classObject?:  {
      __typename: "ClassObject",
      id: string,
      organizationID: string,
      curriculumId: string,
      curriculum:  {
        __typename: "CurriculumData",
        name: string,
        description: string,
        covers:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        type: CurriculumType,
        sessionTemplates:  Array< {
          __typename: "CurriculumSessionTemplate",
          sessionName: string,
          sessionDescription: string,
          sessionDurationInMinutes: number,
          evaluationItems:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requireSessionPhotoOrVideoFromTeacher: boolean,
          requireSessionSummaryFromTeacher: boolean,
          requireEachStudentPhotoOrVideoFromTeacher: boolean,
          requiredCommunications: Array< StudentCommunicationType >,
        } >,
        endOfClassEvaluationsTemplate:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requiredClassLevelCommunications: Array< StudentCommunicationType >,
      },
      sessions:  Array< {
        __typename: "Session",
        sessionLevelSharedFiles:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        sessionSummary?: string | null,
        startDateTime: string,
        classroom:  {
          __typename: "ClassroomData",
          centreId: string,
          centre:  {
            __typename: "CentreData",
            name: string,
            address: string,
            postcode: string,
            country: string,
          },
          name: string,
        },
      } >,
      classLevelSharedFiles:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      classCommunicationOption:  {
        __typename: "ClassCommunicationOption",
        isSendToParent: boolean,
        sendToNonParentEmail?: string | null,
        sendToNonParentPhoneNumber?: string | null,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
  },
};

export type UpdateClassStatusMutationVariables = {
  classID: string,
  status: ItemStatus,
};

export type UpdateClassStatusMutation = {
  updateClassStatus:  {
    __typename: "ClassObject",
    id: string,
    organizationID: string,
    curriculumId: string,
    curriculum:  {
      __typename: "CurriculumData",
      name: string,
      description: string,
      covers:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      type: CurriculumType,
      sessionTemplates:  Array< {
        __typename: "CurriculumSessionTemplate",
        sessionName: string,
        sessionDescription: string,
        sessionDurationInMinutes: number,
        evaluationItems:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requireSessionPhotoOrVideoFromTeacher: boolean,
        requireSessionSummaryFromTeacher: boolean,
        requireEachStudentPhotoOrVideoFromTeacher: boolean,
        requiredCommunications: Array< StudentCommunicationType >,
      } >,
      endOfClassEvaluationsTemplate:  Array< {
        __typename: "EvaluationItem",
        metric: EvaluationMetric,
        formats:  Array< {
          __typename: "EvaluationMetricFormatValue",
          format: EvaluationMetricFormat,
          required: boolean,
        } >,
      } >,
      requiredClassLevelCommunications: Array< StudentCommunicationType >,
    },
    sessions:  Array< {
      __typename: "Session",
      sessionLevelSharedFiles:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      sessionSummary?: string | null,
      startDateTime: string,
      classroom:  {
        __typename: "ClassroomData",
        centreId: string,
        centre:  {
          __typename: "CentreData",
          name: string,
          address: string,
          postcode: string,
          country: string,
        },
        name: string,
      },
    } >,
    classLevelSharedFiles:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } >,
    classCommunicationOption:  {
      __typename: "ClassCommunicationOption",
      isSendToParent: boolean,
      sendToNonParentEmail?: string | null,
      sendToNonParentPhoneNumber?: string | null,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateClassStudentRecordsMutationVariables = {
  createClassStudentRecordInputs: Array< CreateClassStudentRecordInput >,
};

export type CreateClassStudentRecordsMutation = {
  createClassStudentRecords:  Array< {
    __typename: "ClassStudentRecord",
    id: string,
    organizationID: string,
    username: string,
    classID: string,
    sessionRecords:  Array< {
      __typename: "SessionRecordForStudent",
      isAssigned: boolean,
      attended?: boolean | null,
      notAttendedReason?: string | null,
      documents:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      sessionEvaluations:  Array< {
        __typename: "EvaluationItemWithResults",
        metric: EvaluationMetric,
        results:  Array< {
          __typename: "EvaluationMetricFormatResult",
          format: EvaluationMetricFormat,
          required: boolean,
          result?: string | null,
        } >,
      } >,
      sentCommunications: Array< StudentCommunicationType >,
      updatedAt: string,
      updatedByUsername: string,
    } >,
    endOfClassEvaluations:  Array< {
      __typename: "EvaluationItemWithResults",
      metric: EvaluationMetric,
      results:  Array< {
        __typename: "EvaluationMetricFormatResult",
        format: EvaluationMetricFormat,
        required: boolean,
        result?: string | null,
      } >,
    } >,
    sentClassLevelCommunications: Array< StudentCommunicationType >,
    documents:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } >,
    createdAt: string,
    updatedAt: string,
  } >,
};

export type UpdateClassStudentRecordsMutationVariables = {
  updateClassStudentRecordInputs: Array< UpdateClassStudentRecordInput >,
};

export type UpdateClassStudentRecordsMutation = {
  updateClassStudentRecords:  {
    __typename: "UpdateClassStudentRecordsOutput",
    errorReasonForUser?: string | null,
    results:  Array< {
      __typename: "ClassStudentRecord",
      id: string,
      organizationID: string,
      username: string,
      classID: string,
      sessionRecords:  Array< {
        __typename: "SessionRecordForStudent",
        isAssigned: boolean,
        attended?: boolean | null,
        notAttendedReason?: string | null,
        documents:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        sessionEvaluations:  Array< {
          __typename: "EvaluationItemWithResults",
          metric: EvaluationMetric,
          results:  Array< {
            __typename: "EvaluationMetricFormatResult",
            format: EvaluationMetricFormat,
            required: boolean,
            result?: string | null,
          } >,
        } >,
        sentCommunications: Array< StudentCommunicationType >,
        updatedAt: string,
        updatedByUsername: string,
      } >,
      endOfClassEvaluations:  Array< {
        __typename: "EvaluationItemWithResults",
        metric: EvaluationMetric,
        results:  Array< {
          __typename: "EvaluationMetricFormatResult",
          format: EvaluationMetricFormat,
          required: boolean,
          result?: string | null,
        } >,
      } >,
      sentClassLevelCommunications: Array< StudentCommunicationType >,
      documents:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
  },
};

export type RemoveClassStudentRecordsMutationVariables = {
  removeClassStudentRecordInputs: Array< RemoveClassStudentRecordInput >,
};

export type RemoveClassStudentRecordsMutation = {
  removeClassStudentRecords: boolean,
};

export type CreateClassTeacherRecordsMutationVariables = {
  createClassTeacherRecordInputs: Array< CreateClassTeacherRecordInput >,
};

export type CreateClassTeacherRecordsMutation = {
  createClassTeacherRecords:  Array< {
    __typename: "ClassTeacherRecord",
    id: string,
    organizationID: string,
    username: string,
    classID: string,
    sessionRecords:  Array< {
      __typename: "SessionRecordForTeacher",
      role: TeacherRole,
      documents:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      updatedAt: string,
      updatedByUsername: string,
    } | null >,
    documents:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } >,
    createdAt: string,
    updatedAt: string,
  } >,
};

export type UpdateClassTeacherRecordsMutationVariables = {
  updateClassTeacherRecordInputs: Array< UpdateClassTeacherRecordInput >,
};

export type UpdateClassTeacherRecordsMutation = {
  updateClassTeacherRecords:  {
    __typename: "UpdateClassTeacherRecordsOutput",
    errorReasonForUser?: string | null,
    results:  Array< {
      __typename: "ClassTeacherRecord",
      id: string,
      organizationID: string,
      username: string,
      classID: string,
      sessionRecords:  Array< {
        __typename: "SessionRecordForTeacher",
        role: TeacherRole,
        documents:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        updatedAt: string,
        updatedByUsername: string,
      } | null >,
      documents:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
  },
};

export type RemoveClassTeacherRecordsMutationVariables = {
  removeClassTeacherRecordInputs: Array< RemoveClassTeacherRecordInput >,
};

export type RemoveClassTeacherRecordsMutation = {
  removeClassTeacherRecords: boolean,
};

export type SendWhatsAppTextMessageMutationVariables = {
  body: string,
  toPhoneNumber: string,
};

export type SendWhatsAppTextMessageMutation = {
  sendWhatsAppTextMessage: boolean,
};

export type SendWhatsAppCommunicationMutationVariables = {
  classStudentRecordId: string,
  classID: string,
  sessionIndex?: number | null,
  communicationType: StudentCommunicationType,
};

export type SendWhatsAppCommunicationMutation = {
  sendWhatsAppCommunication: boolean,
};

export type GetGlobalConfigurationsQueryVariables = {
  id: string,
};

export type GetGlobalConfigurationsQuery = {
  getGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGlobalConfigurationsQueryVariables = {
  id?: string | null,
  filter?: ModelGlobalConfigurationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGlobalConfigurationsQuery = {
  listGlobalConfigurations?:  {
    __typename: "ModelGlobalConfigurationsConnection",
    items:  Array< {
      __typename: "GlobalConfigurations",
      id: string,
      latestAppVersion: string,
      miniApps?:  Array< {
        __typename: "LyzaMiniApp",
        id: string,
        type: LyzaMiniAppType,
        timestamp: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRobotActionQueryVariables = {
  organizationID: string,
  actionID: string,
};

export type GetRobotActionQuery = {
  getRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRobotActionsQueryVariables = {
  organizationID?: string | null,
  actionID?: ModelIDKeyConditionInput | null,
  filter?: ModelRobotActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRobotActionsQuery = {
  listRobotActions?:  {
    __typename: "ModelRobotActionConnection",
    items:  Array< {
      __typename: "RobotAction",
      organizationID: string,
      actionID: string,
      actionName: string,
      actionNameEmbedding: Array< number >,
      embeddingModel: EmbeddingModel,
      textToSayEn?: string | null,
      textToSayZh?: string | null,
      actionBlocklyProjectId?: string | null,
      actionBlocklyProjectVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAudioRecordingQueryVariables = {
  s3bucketKey: string,
};

export type GetAudioRecordingQuery = {
  getAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAudioRecordingsQueryVariables = {
  s3bucketKey?: string | null,
  filter?: ModelAudioRecordingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAudioRecordingsQuery = {
  listAudioRecordings?:  {
    __typename: "ModelAudioRecordingConnection",
    items:  Array< {
      __typename: "AudioRecording",
      s3bucketKey: string,
      whisperResult?: string | null,
      googleSpeechToText?: string | null,
      awsTranscribeResult?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationHistoryQueryVariables = {
  deviceID: string,
  startDateTime: string,
};

export type GetConversationHistoryQuery = {
  getConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConversationHistoriesQueryVariables = {
  deviceID?: string | null,
  startDateTime?: ModelStringKeyConditionInput | null,
  filter?: ModelConversationHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConversationHistoriesQuery = {
  listConversationHistories?:  {
    __typename: "ModelConversationHistoryConnection",
    items:  Array< {
      __typename: "ConversationHistory",
      deviceID: string,
      startDateTime: string,
      messages:  Array< {
        __typename: "Message",
        role: Role,
        content: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionAndAnswerRecordsV2QueryVariables = {
  deviceID: string,
  dateTime: string,
};

export type GetQuestionAndAnswerRecordsV2Query = {
  getQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionAndAnswerRecordsV2sQueryVariables = {
  deviceID?: string | null,
  dateTime?: ModelStringKeyConditionInput | null,
  filter?: ModelQuestionAndAnswerRecordsV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionAndAnswerRecordsV2sQuery = {
  listQuestionAndAnswerRecordsV2s?:  {
    __typename: "ModelQuestionAndAnswerRecordsV2Connection",
    items:  Array< {
      __typename: "QuestionAndAnswerRecordsV2",
      deviceID: string,
      dateTime: string,
      source: string,
      question: string,
      answer: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppLoggingQueryVariables = {
  userID: string,
  dateTime: string,
};

export type GetAppLoggingQuery = {
  getAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppLoggingsQueryVariables = {
  userID?: string | null,
  dateTime?: ModelStringKeyConditionInput | null,
  filter?: ModelAppLoggingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAppLoggingsQuery = {
  listAppLoggings?:  {
    __typename: "ModelAppLoggingConnection",
    items:  Array< {
      __typename: "AppLogging",
      userID: string,
      dateTime: string,
      namespace?: string | null,
      message?: string | null,
      stacktrace?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationDataQueryVariables = {
  organizationID: string,
};

export type GetOrganizationDataQuery = {
  getOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type ListOrganizationDataQueryVariables = {
  organizationID?: string | null,
  filter?: ModelOrganizationDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationDataQuery = {
  listOrganizationData?:  {
    __typename: "ModelOrganizationDataConnection",
    items:  Array< {
      __typename: "OrganizationData",
      organizationID: string,
      name: string,
      compreFaceFaceRecognitionApiKey?: string | null,
      usePaidSpeechToText?: boolean | null,
      usePaidTextToSpeech?: boolean | null,
      miniApps?:  Array< {
        __typename: "LyzaMiniApp",
        id: string,
        type: LyzaMiniAppType,
        timestamp: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
      isSchoolManagementEnabled?: boolean | null,
      isKebbiForBusinessEnabled?: boolean | null,
      allowedOrgRoles?: Array< OrgRole > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFaceRecognitionMomentsForImprovementsLogQueryVariables = {
  id: string,
};

export type GetFaceRecognitionMomentsForImprovementsLogQuery = {
  getFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFaceRecognitionMomentsForImprovementsLogsQueryVariables = {
  id?: string | null,
  filter?: ModelFaceRecognitionMomentsForImprovementsLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFaceRecognitionMomentsForImprovementsLogsQuery = {
  listFaceRecognitionMomentsForImprovementsLogs?:  {
    __typename: "ModelFaceRecognitionMomentsForImprovementsLogConnection",
    items:  Array< {
      __typename: "FaceRecognitionMomentsForImprovementsLog",
      id: string,
      kebbiDeviceId: string,
      organizationId: string,
      recognitionHistory:  Array< {
        __typename: "CompreFaceRecognitionResults",
        results:  Array< {
          __typename: "CompreFaceRecognitionResult",
          similarity: number,
          subject: string,
        } >,
      } >,
      outcome?: FaceRecognitionLogOutcome | null,
      logReason: FaceRecognitionLogReason,
      recognizedUser?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetKebbiDeviceQueryVariables = {
  id: string,
};

export type GetKebbiDeviceQuery = {
  getKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type ListKebbiDevicesQueryVariables = {
  id?: string | null,
  filter?: ModelKebbiDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListKebbiDevicesQuery = {
  listKebbiDevices?:  {
    __typename: "ModelKebbiDeviceConnection",
    items:  Array< {
      __typename: "KebbiDevice",
      id: string,
      organizationID?: string | null,
      cognitoIdentityID: string,
      cognitoUserID: string,
      password: string,
      deviceServiceStatus:  Array< {
        __typename: "DeviceServiceStatus",
        serviceName: ServiceName,
        expirationDate?: string | null,
      } >,
      registeredFaces?:  Array< {
        __typename: "KebbiRegisteredFace",
        file?:  Array< {
          __typename: "FileType",
          lastUpdatedTimestamp?: string | null,
          name: string,
          category: FileCategory,
          identityId?: string | null,
        } > | null,
        fullName: string,
        preferredName: string,
      } > | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
      isTestDevice?: boolean | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationUserQueryVariables = {
  organizationID: string,
  username: string,
};

export type GetOrganizationUserQuery = {
  getOrganizationUser?:  {
    __typename: "OrganizationUser",
    organizationID: string,
    username: string,
    status: OrganizationUserStatus,
    role: OrgRole,
    createdAt: string,
    updatedAt: string,
    isShadowAdmin?: boolean | null,
  } | null,
};

export type ListOrganizationUsersQueryVariables = {
  organizationID?: string | null,
  username?: ModelStringKeyConditionInput | null,
  filter?: ModelOrganizationUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationUsersQuery = {
  listOrganizationUsers?:  {
    __typename: "ModelOrganizationUserConnection",
    items:  Array< {
      __typename: "OrganizationUser",
      organizationID: string,
      username: string,
      status: OrganizationUserStatus,
      role: OrgRole,
      createdAt: string,
      updatedAt: string,
      isShadowAdmin?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  userName: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    userName: string,
    cognitoIdentityID?: string | null,
    password?: string | null,
    organizations?: Array< string > | null,
    profileImage?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    parentName?: string | null,
    legalFullName?: string | null,
    preferredName?: string | null,
    country?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    dateOfBirth?: string | null,
    about?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  userName?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      userName: string,
      cognitoIdentityID?: string | null,
      password?: string | null,
      organizations?: Array< string > | null,
      profileImage?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      parentName?: string | null,
      legalFullName?: string | null,
      preferredName?: string | null,
      country?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      dateOfBirth?: string | null,
      about?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTempAuthTokenQueryVariables = {
  id: string,
};

export type GetTempAuthTokenQuery = {
  getTempAuthToken?:  {
    __typename: "TempAuthToken",
    id: string,
    username: string,
    expirationAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTempAuthTokensQueryVariables = {
  id?: string | null,
  filter?: ModelTempAuthTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTempAuthTokensQuery = {
  listTempAuthTokens?:  {
    __typename: "ModelTempAuthTokenConnection",
    items:  Array< {
      __typename: "TempAuthToken",
      id: string,
      username: string,
      expirationAt: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActivationCodeQueryVariables = {
  id: string,
};

export type GetActivationCodeQuery = {
  getActivationCode?:  {
    __typename: "ActivationCode",
    id: string,
    durationInDays: number,
    serviceNames: Array< ServiceName >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListActivationCodesQueryVariables = {
  id?: string | null,
  filter?: ModelActivationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActivationCodesQuery = {
  listActivationCodes?:  {
    __typename: "ModelActivationCodeConnection",
    items:  Array< {
      __typename: "ActivationCode",
      id: string,
      durationInDays: number,
      serviceNames: Array< ServiceName >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetKebbiMaterialsQueryVariables = {
  materialType: KebbiMaterialType,
  page: number,
};

export type GetKebbiMaterialsQuery = {
  getKebbiMaterials?:  {
    __typename: "KebbiMaterials",
    materialType: KebbiMaterialType,
    page: number,
    materials:  Array< {
      __typename: "KebbiMaterial",
      title: string,
      i18n:  {
        __typename: "i18n",
        en_US: string,
      },
      identityID: string,
      fileName: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKebbiMaterialsQueryVariables = {
  materialType?: KebbiMaterialType | null,
  page?: ModelIntKeyConditionInput | null,
  filter?: ModelKebbiMaterialsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListKebbiMaterialsQuery = {
  listKebbiMaterials?:  {
    __typename: "ModelKebbiMaterialsConnection",
    items:  Array< {
      __typename: "KebbiMaterials",
      materialType: KebbiMaterialType,
      page: number,
      materials:  Array< {
        __typename: "KebbiMaterial",
        title: string,
        i18n:  {
          __typename: "i18n",
          en_US: string,
        },
        identityID: string,
        fileName: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBlocklyProjectQueryVariables = {
  id: string,
};

export type GetBlocklyProjectQuery = {
  getBlocklyProject?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type ListBlocklyProjectsQueryVariables = {
  id?: string | null,
  filter?: ModelBlocklyProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBlocklyProjectsQuery = {
  listBlocklyProjects?:  {
    __typename: "ModelBlocklyProjectConnection",
    items:  Array< {
      __typename: "BlocklyProject",
      id: string,
      groupID?: string | null,
      workspaceJson: string,
      projectName: string,
      identityID: string,
      files:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } >,
      filesV2?:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      machineLearningProjectIDs?: Array< string > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSubscriptionEventStatusQueryVariables = {
  userID: string,
};

export type GetUserSubscriptionEventStatusQuery = {
  getUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserSubscriptionEventStatusesQueryVariables = {
  userID?: string | null,
  filter?: ModelUserSubscriptionEventStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserSubscriptionEventStatusesQuery = {
  listUserSubscriptionEventStatuses?:  {
    __typename: "ModelUserSubscriptionEventStatusConnection",
    items:  Array< {
      __typename: "UserSubscriptionEventStatus",
      userID: string,
      machineLearning?:  {
        __typename: "MachineLearningStatus",
        tfjsToTfliteConversionStatus?: ProcessStatus | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserWhatsAppMessageQueryVariables = {
  messageID: string,
};

export type GetUserWhatsAppMessageQuery = {
  getUserWhatsAppMessage?:  {
    __typename: "UserWhatsAppMessage",
    messageID: string,
    customerNumber: string,
    businessPhoneNumber: string,
    isFromBizToCustomer: boolean,
    timestampInSecond: number,
    status: MessageSendStatus,
    textMessage?: string | null,
    imageS3Key?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    videoS3Key?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    documentS3Key?:  {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } | null,
    reactionEmoji?: string | null,
    quotedMessageId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserWhatsAppMessagesQueryVariables = {
  messageID?: string | null,
  filter?: ModelUserWhatsAppMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserWhatsAppMessagesQuery = {
  listUserWhatsAppMessages?:  {
    __typename: "ModelUserWhatsAppMessageConnection",
    items:  Array< {
      __typename: "UserWhatsAppMessage",
      messageID: string,
      customerNumber: string,
      businessPhoneNumber: string,
      isFromBizToCustomer: boolean,
      timestampInSecond: number,
      status: MessageSendStatus,
      textMessage?: string | null,
      imageS3Key?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      videoS3Key?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      documentS3Key?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      reactionEmoji?: string | null,
      quotedMessageId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBlocklyProjectByIDQueryVariables = {
  id: string,
};

export type GetBlocklyProjectByIDQuery = {
  getBlocklyProjectByID?:  {
    __typename: "BlocklyProject",
    id: string,
    groupID?: string | null,
    workspaceJson: string,
    projectName: string,
    identityID: string,
    files:  Array< {
      __typename: "FileType",
      lastUpdatedTimestamp?: string | null,
      name: string,
      category: FileCategory,
      identityId?: string | null,
    } >,
    filesV2?:  Array< {
      __typename: "S3File",
      s3Key: string,
      versionNumber: number,
      documentType: DocumentType,
    } > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    machineLearningProjectIDs?: Array< string > | null,
  } | null,
};

export type GetBlocklyProjectsByOwnerQueryVariables = {
  owner: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type GetBlocklyProjectsByOwnerQuery = {
  getBlocklyProjectsByOwner?:  {
    __typename: "ListBlocklyProjectsOutput",
    items:  Array< {
      __typename: "BlocklyProject",
      id: string,
      groupID?: string | null,
      workspaceJson: string,
      projectName: string,
      identityID: string,
      files:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } >,
      filesV2?:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      machineLearningProjectIDs?: Array< string > | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetBlocklyProjectsByGroupIDQueryVariables = {
  groupID: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type GetBlocklyProjectsByGroupIDQuery = {
  getBlocklyProjectsByGroupID?:  {
    __typename: "ListBlocklyProjectsOutput",
    items:  Array< {
      __typename: "BlocklyProject",
      id: string,
      groupID?: string | null,
      workspaceJson: string,
      projectName: string,
      identityID: string,
      files:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } >,
      filesV2?:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      machineLearningProjectIDs?: Array< string > | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables = {
  deviceID: string,
  startDate: string,
  endDate: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery = {
  listConversationHistoryByDeviceIdAndDateRangeInclusive:  {
    __typename: "ListConversationHistoryOutput",
    items:  Array< {
      __typename: "ConversationHistory",
      deviceID: string,
      startDateTime: string,
      messages:  Array< {
        __typename: "Message",
        role: Role,
        content: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  },
};

export type ListOrganizationUsersByOrganizationIDQueryVariables = {
  organizationID: string,
  organizationName: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListOrganizationUsersByOrganizationIDQuery = {
  listOrganizationUsersByOrganizationID:  {
    __typename: "ListOrganizationUsersByOrganizationIDOutput",
    items:  Array< {
      __typename: "UserWithOrgData",
      user:  {
        __typename: "User",
        userName: string,
        cognitoIdentityID?: string | null,
        password?: string | null,
        organizations?: Array< string > | null,
        profileImage?:  {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } | null,
        parentName?: string | null,
        legalFullName?: string | null,
        preferredName?: string | null,
        country?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        dateOfBirth?: string | null,
        about?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      organizations:  Array< {
        __typename: "OrganizationDataForUser",
        userOrg:  {
          __typename: "OrganizationUser",
          organizationID: string,
          username: string,
          status: OrganizationUserStatus,
          role: OrgRole,
          createdAt: string,
          updatedAt: string,
          isShadowAdmin?: boolean | null,
        },
        orgName: string,
        userOrgPermission:  {
          __typename: "UserOrgPermission",
          canReadUsers: boolean,
          canCreateUser: boolean,
          canListCurriculums: boolean,
          canCreateCurriculum: boolean,
          canListClasses: boolean,
          canCreateClass: boolean,
          canListCentres: boolean,
          canCreateCentres: boolean,
          canListClassrooms: boolean,
          canCreateClassrooms: boolean,
          isKebbiForBusinessEnabled: boolean,
          isCodelabProEnabled: boolean,
        },
        allowedOrgRoles: Array< OrgRole >,
      } >,
    } >,
    nextToken?: string | null,
  },
};

export type GetUserByUsernameQueryVariables = {
  username: string,
};

export type GetUserByUsernameQuery = {
  getUserByUsername?:  {
    __typename: "UserWithOrgData",
    user:  {
      __typename: "User",
      userName: string,
      cognitoIdentityID?: string | null,
      password?: string | null,
      organizations?: Array< string > | null,
      profileImage?:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } | null,
      parentName?: string | null,
      legalFullName?: string | null,
      preferredName?: string | null,
      country?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      dateOfBirth?: string | null,
      about?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    organizations:  Array< {
      __typename: "OrganizationDataForUser",
      userOrg:  {
        __typename: "OrganizationUser",
        organizationID: string,
        username: string,
        status: OrganizationUserStatus,
        role: OrgRole,
        createdAt: string,
        updatedAt: string,
        isShadowAdmin?: boolean | null,
      },
      orgName: string,
      userOrgPermission:  {
        __typename: "UserOrgPermission",
        canReadUsers: boolean,
        canCreateUser: boolean,
        canListCurriculums: boolean,
        canCreateCurriculum: boolean,
        canListClasses: boolean,
        canCreateClass: boolean,
        canListCentres: boolean,
        canCreateCentres: boolean,
        canListClassrooms: boolean,
        canCreateClassrooms: boolean,
        isKebbiForBusinessEnabled: boolean,
        isCodelabProEnabled: boolean,
      },
      allowedOrgRoles: Array< OrgRole >,
    } >,
  } | null,
};

export type ListKebbiDevicesForOrganizationQueryVariables = {
  organizationID: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListKebbiDevicesForOrganizationQuery = {
  listKebbiDevicesForOrganization:  {
    __typename: "ListKebbiDevicesByOrganizationIDOutput",
    items:  Array< {
      __typename: "KebbiDeviceForOrganization",
      id: string,
      organizationID: string,
      cognitoIdentityID: string,
      cognitoUserID: string,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
      isTestDevice?: boolean | null,
    } >,
    nextToken?: string | null,
  },
};

export type ListRobotFAQsByOrganizationIDQueryVariables = {
  organizationID: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListRobotFAQsByOrganizationIDQuery = {
  listRobotFAQsByOrganizationID:  {
    __typename: "ListRobotFAQsOutput",
    robotFAQs:  Array< {
      __typename: "RobotFAQ",
      organizationID: string,
      question: string,
      questionEmbedding: Array< number >,
      embeddingModel: EmbeddingModel,
      textToSayEn: string,
      textToSayZh: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  },
};

export type GetMachineLearningProjectV2QueryVariables = {
  id: string,
};

export type GetMachineLearningProjectV2Query = {
  getMachineLearningProjectV2?:  {
    __typename: "MachineLearningProject",
    id: string,
    ownerID: string,
    ownerType: OwnerType,
    projectName: string,
    modelType: MachineLearningModelType,
    modelS3RootDirectory: string,
    classes:  Array< {
      __typename: "MachineLearningClass",
      id: string,
      name: string,
      samplesZipS3File:  {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      },
      isDisabled: boolean,
    } >,
    targetLatestModelVersion?: number | null,
    tfjsModelVersion?: number | null,
    tfliteModelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    blocklyProjectIDs?: Array< string > | null,
  } | null,
};

export type ListMachineLearningProjectsByIdAndModelTypeQueryVariables = {
  ownerID: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListMachineLearningProjectsByIdAndModelTypeQuery = {
  listMachineLearningProjectsByIdAndModelType:  {
    __typename: "ListMachineLearningProjectsOutput",
    items:  Array< {
      __typename: "MachineLearningProject",
      id: string,
      ownerID: string,
      ownerType: OwnerType,
      projectName: string,
      modelType: MachineLearningModelType,
      modelS3RootDirectory: string,
      classes:  Array< {
        __typename: "MachineLearningClass",
        id: string,
        name: string,
        samplesZipS3File:  {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        },
        isDisabled: boolean,
      } >,
      targetLatestModelVersion?: number | null,
      tfjsModelVersion?: number | null,
      tfliteModelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      blocklyProjectIDs?: Array< string > | null,
    } >,
    nextToken?: string | null,
  },
};

export type GetCodespaceProjectQueryVariables = {
  id: string,
};

export type GetCodespaceProjectQuery = {
  getCodespaceProject?:  {
    __typename: "CodespaceProject",
    id: string,
    ownerID: string,
    ownerType: OwnerType,
    projectName: string,
    language: Languages,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCodespaceProjectsByIdQueryVariables = {
  ownerID: string,
  nextToken?: string | null,
  limit?: number | null,
};

export type ListCodespaceProjectsByIdQuery = {
  listCodespaceProjectsById:  {
    __typename: "ListCodespaceProjectsOutput",
    items:  Array< {
      __typename: "CodespaceProject",
      id: string,
      ownerID: string,
      ownerType: OwnerType,
      projectName: string,
      language: Languages,
      code: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  },
};

export type GetS3FileDownloadLinksQueryVariables = {
  s3Keys: Array< string >,
};

export type GetS3FileDownloadLinksQuery = {
  getS3FileDownloadLinks:  Array< {
    __typename: "FileDownloadLink",
    s3Key: string,
    s3PreSignedUrl: string,
  } >,
};

export type GetUserProfileImageUploadLinkAndS3KeyQueryVariables = {
  username: string,
  contentType: string,
};

export type GetUserProfileImageUploadLinkAndS3KeyQuery = {
  getUserProfileImageUploadLinkAndS3Key:  {
    __typename: "FileUploadLink",
    s3Key: string,
    s3PreSignedUrl: string,
    documentType: DocumentType,
  },
};

export type GetFilesUploadLinksAndS3KeysQueryVariables = {
  fileUploadInputs: Array< S3FileUploadInput >,
};

export type GetFilesUploadLinksAndS3KeysQuery = {
  getFilesUploadLinksAndS3Keys:  Array< {
    __typename: "FileUploadLink",
    s3Key: string,
    s3PreSignedUrl: string,
    documentType: DocumentType,
  } >,
};

export type ListCentresQueryVariables = {
  id?: string | null,
  organizationID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCentresQuery = {
  listCentres?:  {
    __typename: "ListCentresOutput",
    items:  Array< {
      __typename: "Centre",
      id: string,
      organizationID: string,
      data:  {
        __typename: "CentreData",
        name: string,
        address: string,
        postcode: string,
        country: string,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListClassroomsQueryVariables = {
  id?: string | null,
  organizationID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClassroomsQuery = {
  listClassrooms:  {
    __typename: "ListClassroomOutput",
    items:  Array< {
      __typename: "Classroom",
      id: string,
      organizationID: string,
      data:  {
        __typename: "ClassroomData",
        centreId: string,
        centre:  {
          __typename: "CentreData",
          name: string,
          address: string,
          postcode: string,
          country: string,
        },
        name: string,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  },
};

export type GetCurriculumQueryVariables = {
  id: string,
};

export type GetCurriculumQuery = {
  getCurriculum?:  {
    __typename: "Curriculum",
    id: string,
    organizationID: string,
    data:  {
      __typename: "CurriculumData",
      name: string,
      description: string,
      covers:  Array< {
        __typename: "S3File",
        s3Key: string,
        versionNumber: number,
        documentType: DocumentType,
      } >,
      type: CurriculumType,
      sessionTemplates:  Array< {
        __typename: "CurriculumSessionTemplate",
        sessionName: string,
        sessionDescription: string,
        sessionDurationInMinutes: number,
        evaluationItems:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requireSessionPhotoOrVideoFromTeacher: boolean,
        requireSessionSummaryFromTeacher: boolean,
        requireEachStudentPhotoOrVideoFromTeacher: boolean,
        requiredCommunications: Array< StudentCommunicationType >,
      } >,
      endOfClassEvaluationsTemplate:  Array< {
        __typename: "EvaluationItem",
        metric: EvaluationMetric,
        formats:  Array< {
          __typename: "EvaluationMetricFormatValue",
          format: EvaluationMetricFormat,
          required: boolean,
        } >,
      } >,
      requiredClassLevelCommunications: Array< StudentCommunicationType >,
    },
    status: ItemStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCurriculumsQueryVariables = {
  organizationId: string,
  nextToken?: string | null,
};

export type ListCurriculumsQuery = {
  listCurriculums:  {
    __typename: "ListCurriculumOutput",
    items:  Array< {
      __typename: "Curriculum",
      id: string,
      organizationID: string,
      data:  {
        __typename: "CurriculumData",
        name: string,
        description: string,
        covers:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        type: CurriculumType,
        sessionTemplates:  Array< {
          __typename: "CurriculumSessionTemplate",
          sessionName: string,
          sessionDescription: string,
          sessionDurationInMinutes: number,
          evaluationItems:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requireSessionPhotoOrVideoFromTeacher: boolean,
          requireSessionSummaryFromTeacher: boolean,
          requireEachStudentPhotoOrVideoFromTeacher: boolean,
          requiredCommunications: Array< StudentCommunicationType >,
        } >,
        endOfClassEvaluationsTemplate:  Array< {
          __typename: "EvaluationItem",
          metric: EvaluationMetric,
          formats:  Array< {
            __typename: "EvaluationMetricFormatValue",
            format: EvaluationMetricFormat,
            required: boolean,
          } >,
        } >,
        requiredClassLevelCommunications: Array< StudentCommunicationType >,
      },
      status: ItemStatus,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  },
};

export type ListClassObjectsWithAttendeeDetailsByUsernameQueryVariables = {
  username: string,
  role: OrgRole,
  orgId: string,
  nextToken?: string | null,
};

export type ListClassObjectsWithAttendeeDetailsByUsernameQuery = {
  listClassObjectsWithAttendeeDetailsByUsername:  {
    __typename: "ListClassObjectsWithAttendeeDetailsByUsernameOutput",
    items:  Array< {
      __typename: "ClassObjectWithAttendeeDetails",
      classObject:  {
        __typename: "ClassObject",
        id: string,
        organizationID: string,
        curriculumId: string,
        curriculum:  {
          __typename: "CurriculumData",
          name: string,
          description: string,
          covers:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          type: CurriculumType,
          sessionTemplates:  Array< {
            __typename: "CurriculumSessionTemplate",
            sessionName: string,
            sessionDescription: string,
            sessionDurationInMinutes: number,
            evaluationItems:  Array< {
              __typename: "EvaluationItem",
              metric: EvaluationMetric,
              formats:  Array< {
                __typename: "EvaluationMetricFormatValue",
                format: EvaluationMetricFormat,
                required: boolean,
              } >,
            } >,
            requireSessionPhotoOrVideoFromTeacher: boolean,
            requireSessionSummaryFromTeacher: boolean,
            requireEachStudentPhotoOrVideoFromTeacher: boolean,
            requiredCommunications: Array< StudentCommunicationType >,
          } >,
          endOfClassEvaluationsTemplate:  Array< {
            __typename: "EvaluationItem",
            metric: EvaluationMetric,
            formats:  Array< {
              __typename: "EvaluationMetricFormatValue",
              format: EvaluationMetricFormat,
              required: boolean,
            } >,
          } >,
          requiredClassLevelCommunications: Array< StudentCommunicationType >,
        },
        sessions:  Array< {
          __typename: "Session",
          sessionLevelSharedFiles:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          sessionSummary?: string | null,
          startDateTime: string,
          classroom:  {
            __typename: "ClassroomData",
            centreId: string,
            centre:  {
              __typename: "CentreData",
              name: string,
              address: string,
              postcode: string,
              country: string,
            },
            name: string,
          },
        } >,
        classLevelSharedFiles:  Array< {
          __typename: "S3File",
          s3Key: string,
          versionNumber: number,
          documentType: DocumentType,
        } >,
        classCommunicationOption:  {
          __typename: "ClassCommunicationOption",
          isSendToParent: boolean,
          sendToNonParentEmail?: string | null,
          sendToNonParentPhoneNumber?: string | null,
        },
        status: ItemStatus,
        createdAt: string,
        updatedAt: string,
      },
      students:  Array< {
        __typename: "ClassStudentRecordWithStudentDetails",
        record:  {
          __typename: "ClassStudentRecord",
          id: string,
          organizationID: string,
          username: string,
          classID: string,
          sessionRecords:  Array< {
            __typename: "SessionRecordForStudent",
            isAssigned: boolean,
            attended?: boolean | null,
            notAttendedReason?: string | null,
            documents:  Array< {
              __typename: "S3File",
              s3Key: string,
              versionNumber: number,
              documentType: DocumentType,
            } >,
            sessionEvaluations:  Array< {
              __typename: "EvaluationItemWithResults",
              metric: EvaluationMetric,
              results:  Array< {
                __typename: "EvaluationMetricFormatResult",
                format: EvaluationMetricFormat,
                required: boolean,
                result?: string | null,
              } >,
            } >,
            sentCommunications: Array< StudentCommunicationType >,
            updatedAt: string,
            updatedByUsername: string,
          } >,
          endOfClassEvaluations:  Array< {
            __typename: "EvaluationItemWithResults",
            metric: EvaluationMetric,
            results:  Array< {
              __typename: "EvaluationMetricFormatResult",
              format: EvaluationMetricFormat,
              required: boolean,
              result?: string | null,
            } >,
          } >,
          sentClassLevelCommunications: Array< StudentCommunicationType >,
          documents:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          createdAt: string,
          updatedAt: string,
        },
        userDetails:  {
          __typename: "User",
          userName: string,
          cognitoIdentityID?: string | null,
          password?: string | null,
          organizations?: Array< string > | null,
          profileImage?:  {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } | null,
          parentName?: string | null,
          legalFullName?: string | null,
          preferredName?: string | null,
          country?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          dateOfBirth?: string | null,
          about?: string | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } >,
      teachers:  Array< {
        __typename: "ClassTeacherRecordWithTeacherDetails",
        record:  {
          __typename: "ClassTeacherRecord",
          id: string,
          organizationID: string,
          username: string,
          classID: string,
          sessionRecords:  Array< {
            __typename: "SessionRecordForTeacher",
            role: TeacherRole,
            documents:  Array< {
              __typename: "S3File",
              s3Key: string,
              versionNumber: number,
              documentType: DocumentType,
            } >,
            updatedAt: string,
            updatedByUsername: string,
          } | null >,
          documents:  Array< {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } >,
          createdAt: string,
          updatedAt: string,
        },
        userDetails:  {
          __typename: "User",
          userName: string,
          cognitoIdentityID?: string | null,
          password?: string | null,
          organizations?: Array< string > | null,
          profileImage?:  {
            __typename: "S3File",
            s3Key: string,
            versionNumber: number,
            documentType: DocumentType,
          } | null,
          parentName?: string | null,
          legalFullName?: string | null,
          preferredName?: string | null,
          country?: string | null,
          email?: string | null,
          phoneNumber?: string | null,
          dateOfBirth?: string | null,
          about?: string | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } >,
    } >,
    nextToken?: string | null,
  },
};

export type GetCredentialsFromAuthTokenQueryVariables = {
  tokenId: string,
};

export type GetCredentialsFromAuthTokenQuery = {
  getCredentialsFromAuthToken?:  {
    __typename: "GetCredentialsFromAuthTokenResponse",
    errorMessage?: string | null,
    username?: string | null,
    pwd?: string | null,
  } | null,
};

export type OnCreateGlobalConfigurationsSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalConfigurationsFilterInput | null,
};

export type OnCreateGlobalConfigurationsSubscription = {
  onCreateGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGlobalConfigurationsSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalConfigurationsFilterInput | null,
};

export type OnUpdateGlobalConfigurationsSubscription = {
  onUpdateGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGlobalConfigurationsSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalConfigurationsFilterInput | null,
};

export type OnDeleteGlobalConfigurationsSubscription = {
  onDeleteGlobalConfigurations?:  {
    __typename: "GlobalConfigurations",
    id: string,
    latestAppVersion: string,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRobotActionSubscriptionVariables = {
  filter?: ModelSubscriptionRobotActionFilterInput | null,
};

export type OnCreateRobotActionSubscription = {
  onCreateRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRobotActionSubscriptionVariables = {
  filter?: ModelSubscriptionRobotActionFilterInput | null,
};

export type OnUpdateRobotActionSubscription = {
  onUpdateRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRobotActionSubscriptionVariables = {
  filter?: ModelSubscriptionRobotActionFilterInput | null,
};

export type OnDeleteRobotActionSubscription = {
  onDeleteRobotAction?:  {
    __typename: "RobotAction",
    organizationID: string,
    actionID: string,
    actionName: string,
    actionNameEmbedding: Array< number >,
    embeddingModel: EmbeddingModel,
    textToSayEn?: string | null,
    textToSayZh?: string | null,
    actionBlocklyProjectId?: string | null,
    actionBlocklyProjectVersion?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAudioRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionAudioRecordingFilterInput | null,
};

export type OnCreateAudioRecordingSubscription = {
  onCreateAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAudioRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionAudioRecordingFilterInput | null,
};

export type OnUpdateAudioRecordingSubscription = {
  onUpdateAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAudioRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionAudioRecordingFilterInput | null,
};

export type OnDeleteAudioRecordingSubscription = {
  onDeleteAudioRecording?:  {
    __typename: "AudioRecording",
    s3bucketKey: string,
    whisperResult?: string | null,
    googleSpeechToText?: string | null,
    awsTranscribeResult?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConversationHistorySubscriptionVariables = {
  filter?: ModelSubscriptionConversationHistoryFilterInput | null,
};

export type OnCreateConversationHistorySubscription = {
  onCreateConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationHistorySubscriptionVariables = {
  filter?: ModelSubscriptionConversationHistoryFilterInput | null,
};

export type OnUpdateConversationHistorySubscription = {
  onUpdateConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConversationHistorySubscriptionVariables = {
  filter?: ModelSubscriptionConversationHistoryFilterInput | null,
};

export type OnDeleteConversationHistorySubscription = {
  onDeleteConversationHistory?:  {
    __typename: "ConversationHistory",
    deviceID: string,
    startDateTime: string,
    messages:  Array< {
      __typename: "Message",
      role: Role,
      content: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionAndAnswerRecordsV2SubscriptionVariables = {
  filter?: ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput | null,
};

export type OnCreateQuestionAndAnswerRecordsV2Subscription = {
  onCreateQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionAndAnswerRecordsV2SubscriptionVariables = {
  filter?: ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput | null,
};

export type OnUpdateQuestionAndAnswerRecordsV2Subscription = {
  onUpdateQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionAndAnswerRecordsV2SubscriptionVariables = {
  filter?: ModelSubscriptionQuestionAndAnswerRecordsV2FilterInput | null,
};

export type OnDeleteQuestionAndAnswerRecordsV2Subscription = {
  onDeleteQuestionAndAnswerRecordsV2?:  {
    __typename: "QuestionAndAnswerRecordsV2",
    deviceID: string,
    dateTime: string,
    source: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAppLoggingSubscriptionVariables = {
  filter?: ModelSubscriptionAppLoggingFilterInput | null,
};

export type OnCreateAppLoggingSubscription = {
  onCreateAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppLoggingSubscriptionVariables = {
  filter?: ModelSubscriptionAppLoggingFilterInput | null,
};

export type OnUpdateAppLoggingSubscription = {
  onUpdateAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppLoggingSubscriptionVariables = {
  filter?: ModelSubscriptionAppLoggingFilterInput | null,
};

export type OnDeleteAppLoggingSubscription = {
  onDeleteAppLogging?:  {
    __typename: "AppLogging",
    userID: string,
    dateTime: string,
    namespace?: string | null,
    message?: string | null,
    stacktrace?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrganizationDataSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationDataFilterInput | null,
};

export type OnCreateOrganizationDataSubscription = {
  onCreateOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type OnUpdateOrganizationDataSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationDataFilterInput | null,
};

export type OnUpdateOrganizationDataSubscription = {
  onUpdateOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type OnDeleteOrganizationDataSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationDataFilterInput | null,
};

export type OnDeleteOrganizationDataSubscription = {
  onDeleteOrganizationData?:  {
    __typename: "OrganizationData",
    organizationID: string,
    name: string,
    compreFaceFaceRecognitionApiKey?: string | null,
    usePaidSpeechToText?: boolean | null,
    usePaidTextToSpeech?: boolean | null,
    miniApps?:  Array< {
      __typename: "LyzaMiniApp",
      id: string,
      type: LyzaMiniAppType,
      timestamp: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
    isSchoolManagementEnabled?: boolean | null,
    isKebbiForBusinessEnabled?: boolean | null,
    allowedOrgRoles?: Array< OrgRole > | null,
  } | null,
};

export type OnCreateFaceRecognitionMomentsForImprovementsLogSubscriptionVariables = {
  filter?: ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput | null,
};

export type OnCreateFaceRecognitionMomentsForImprovementsLogSubscription = {
  onCreateFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFaceRecognitionMomentsForImprovementsLogSubscriptionVariables = {
  filter?: ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput | null,
};

export type OnUpdateFaceRecognitionMomentsForImprovementsLogSubscription = {
  onUpdateFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFaceRecognitionMomentsForImprovementsLogSubscriptionVariables = {
  filter?: ModelSubscriptionFaceRecognitionMomentsForImprovementsLogFilterInput | null,
};

export type OnDeleteFaceRecognitionMomentsForImprovementsLogSubscription = {
  onDeleteFaceRecognitionMomentsForImprovementsLog?:  {
    __typename: "FaceRecognitionMomentsForImprovementsLog",
    id: string,
    kebbiDeviceId: string,
    organizationId: string,
    recognitionHistory:  Array< {
      __typename: "CompreFaceRecognitionResults",
      results:  Array< {
        __typename: "CompreFaceRecognitionResult",
        similarity: number,
        subject: string,
      } >,
    } >,
    outcome?: FaceRecognitionLogOutcome | null,
    logReason: FaceRecognitionLogReason,
    recognizedUser?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKebbiDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionKebbiDeviceFilterInput | null,
  owner?: string | null,
};

export type OnCreateKebbiDeviceSubscription = {
  onCreateKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateKebbiDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionKebbiDeviceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateKebbiDeviceSubscription = {
  onUpdateKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteKebbiDeviceSubscriptionVariables = {
  filter?: ModelSubscriptionKebbiDeviceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteKebbiDeviceSubscription = {
  onDeleteKebbiDevice?:  {
    __typename: "KebbiDevice",
    id: string,
    organizationID?: string | null,
    cognitoIdentityID: string,
    cognitoUserID: string,
    password: string,
    deviceServiceStatus:  Array< {
      __typename: "DeviceServiceStatus",
      serviceName: ServiceName,
      expirationDate?: string | null,
    } >,
    registeredFaces?:  Array< {
      __typename: "KebbiRegisteredFace",
      file?:  Array< {
        __typename: "FileType",
        lastUpdatedTimestamp?: string | null,
        name: string,
        category: FileCategory,
        identityId?: string | null,
      } > | null,
      fullName: string,
      preferredName: string,
    } > | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    isTestDevice?: boolean | null,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionEventStatusSubscriptionVariables = {
  filter?: ModelSubscriptionUserSubscriptionEventStatusFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscriptionEventStatusSubscription = {
  onCreateUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionEventStatusSubscriptionVariables = {
  filter?: ModelSubscriptionUserSubscriptionEventStatusFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscriptionEventStatusSubscription = {
  onUpdateUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionEventStatusSubscriptionVariables = {
  filter?: ModelSubscriptionUserSubscriptionEventStatusFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscriptionEventStatusSubscription = {
  onDeleteUserSubscriptionEventStatus?:  {
    __typename: "UserSubscriptionEventStatus",
    userID: string,
    machineLearning?:  {
      __typename: "MachineLearningStatus",
      tfjsToTfliteConversionStatus?: ProcessStatus | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
